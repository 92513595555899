import {userConstants} from "../_constants";

export function authentication(state = {}, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            };
        case userConstants.LOGIN_FAILURE:
            return {};
        case userConstants.LOGOUT:
            return {};
        case userConstants.RESET_PASSWORD_REQUEST:
            return {
                is_loading: true,
                user: action.username
            };
        case userConstants.RESET_PASSWORD_SUCCESS:
            return {
                is_loading: false,
                response: action.response
            };
        case userConstants.RESET_PASSWORD_FAILURE:
            return {
                is_loading: false,
                error: action.error
            };
        case userConstants.CONFIRM_PASSWORD_REQUEST:
            return {
                is_loading: true,
                user: action.user
            };
        case userConstants.CONFIRM_PASSWORD_SUCCESS:
            return {
                is_loading: false,
                response: action.response
            };
        case userConstants.CONFIRM_PASSWORD_FAILURE:
            return {
                is_loading: false,
                error: action.error
            };
        default:
            return state;
    }
}
