import {userConstants} from "../_constants";
import {userService} from "../_services";
import {alertActions} from "./";
import {history} from "../_helpers";
import React from "react";
import {BrowserRouter, BrowserRouter as Router, Route} from 'react-router-dom';
import {Redirect, useHistory} from "react-router";

export const userActions = {
    login,
    logout,
    register,
    resetPassword,
    confirmPassword
};

function login(username, password) {
    return dispatch => {
        dispatch(request({username}));

        userService.login(username, password).then(
            user => {
                dispatch(success(user));
                if (user.isActive === true) {
                    history.push("/");
                    window.location.reload();
                } else {
                    localStorage.removeItem("selected_customer");
                    localStorage.removeItem("user");
                    history.push("/confirmpassword?email=" + user.email + "&defaultPassword=" + password);
                    window.location.reload();
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(user) {
        return {type: userConstants.LOGIN_REQUEST, user};
    }

    function success(user) {
        return {type: userConstants.LOGIN_SUCCESS, user};
    }

    function failure(error) {
        return {type: userConstants.LOGIN_FAILURE, error};
    }
}

function logout() {
    userService.logout();
    history.push("/login");
    return {type: userConstants.LOGOUT};
}

function register(user) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user).then(
            user => {
                dispatch(success());
                history.push("/login");
                dispatch(alertActions.success(user.message));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(user) {
        return {type: userConstants.REGISTER_REQUEST, user};
    }

    function success(user) {
        return {type: userConstants.REGISTER_SUCCESS, user};
    }

    function failure(error) {
        return {type: userConstants.REGISTER_FAILURE, error};
    }
}

function confirmPassword(user) {
    return dispatch => {
        dispatch(request(user));

        userService.confirmPassword(user).then(
            response => {
                dispatch(success(response));
                dispatch(alertActions.success(response.message));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(user) {
        return {type: userConstants.CONFIRM_PASSWORD_REQUEST, user};
    }

    function success(response) {
        return {type: userConstants.CONFIRM_PASSWORD_SUCCESS, response};
    }

    function failure(error) {
        return {type: userConstants.CONFIRM_PASSWORD_FAILURE, error};
    }
}

function resetPassword(email) {
    return dispatch => {
        dispatch(request(email));

        userService.resetPassword(email).then(
            response => {
                dispatch(success(response));
                dispatch(alertActions.success(response.message));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(username) {
        return {type: userConstants.RESET_PASSWORD_REQUEST, username};
    }

    function success(response) {
        return {type: userConstants.RESET_PASSWORD_SUCCESS, response};
    }

    function failure(error) {
        return {type: userConstants.RESET_PASSWORD_FAILURE, error};
    }
}
