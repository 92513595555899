import {tagsConstants} from "../_constants";

export function tags(state = {}, action) {
    switch (action.type) {
        case tagsConstants.GET_ALL_TAGS_REQUEST:
            return {
                loading: true
            };
        case tagsConstants.GET_ALL_TAGS_REQUEST_SUCCESS:
            return {
                loading: false,
                items: action.tags
            };
        case tagsConstants.GET_ALL_TAGS_REQUEST_FAILURE:
            return {
                loading: false,
                error: action.error
            };
        case tagsConstants.UPDATE_TAGS_REQUEST:
            return {
                isSavingData: false
            };
        case tagsConstants.UPDATE_TAGS_REQUEST_SUCCESS:
            return {
                isSavingData: true,
                response: action.response
            };
        case tagsConstants.UPDATE_TAGS_REQUEST_FAILURE:
            return {
                isSavingData: false,
                error: action.error
            };
        default:
            return state;
    }
}
