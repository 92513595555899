export const siteSettingsConstants = {
    GET_ALL_SETTINGS_REQUEST: "GET_ALL_SETTINGS_REQUEST",
    GET_ALL_SETTINGS_REQUEST_SUCCESS: "GET_ALL_SETTINGS_REQUEST_SUCCESS",
    GET_ALL_SETTINGS_REQUEST_FAILURE: "GET_ALL_SETTINGS_REQUEST_FAILURE",

    CREATE_SETTINGS_REQUEST: "CREATE_SETTINGS_REQUEST",
    CREATE_SETTINGS_REQUEST_SUCCESS: "CREATE_SETTINGS_REQUEST_SUCCESS",
    CREATE_SETTINGS_REQUEST_FAILURE: "CREATE_SETTINGS_REQUEST_FAILURE",

    UPDATE_SETTINGS_REQUEST: "UPDATE_SETTINGS_REQUEST",
    UPDATE_SETTINGS_REQUEST_SUCCESS: "UPDATE_SETTINGS_REQUEST_SUCCESS",
    UPDATE_SETTINGS_REQUEST_FAILURE: "UPDATE_SETTINGS_REQUEST_FAILURE",

    DELETE_SETTINGS_REQUEST: "DELETE_SETTINGS_REQUEST",
    DELETE_SETTINGS_REQUEST_SUCCESS: "DELETE_SETTINGS_REQUEST_SUCCESS",
    DELETE_SETTINGS_REQUEST_FAILURE: "DELETE_SETTINGS_REQUEST_FAILURE"
};