import {combineReducers} from "redux";

import {authentication} from "./authentication.reducer";
import {registration} from "./registration.reducer";
import {receivers} from "./receivers.reducer";
import {gateways} from "./gateway.reducer";
import {tags} from "./tags.reducer";
import {dispensers} from "./dispenser.reducer";
import {users} from "./users.reducer";
import {liveFeed} from "./livefeed.reducer";
import {home} from "./home.reducer";
import {alert} from "./alert.reducer";
import {siteSettings} from "./sitesettings.reducer";
import {escalations } from "./escalations.reducer";


const rootReducer = combineReducers({
    authentication,
    registration,
    users,
    receivers,
    gateways,
    tags,
    dispensers,
    liveFeed,
    home,
    alert,
    siteSettings,
    escalations
});

export default rootReducer;
