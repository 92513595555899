import {userConstants} from "../_constants";

export function users(state = {}, action) {
    switch (action.type) {
        case userConstants.GETALL_REQUEST:
            return {
                loading: true
            };
        case userConstants.GETALL_SUCCESS:
            return {
                loading: false,
                items: action.users
            };
        case userConstants.GETALL_FAILURE:
            return {
                error: action.error
            };
        case userConstants.GETALL_CUSTOMERS_REQUEST:
            return {
                loading: true
            };
        case userConstants.GETALL_CUSTOMERS_SUCCESS:
            return {
                loading: false,
                items: action.customers,
                defaultCustomer: JSON.parse(localStorage.getItem("selected_customer"))
            };
        case userConstants.GETALL_CUSTOMERS_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
}
