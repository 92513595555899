
export const escalationsConstants = {
    GET_ALL_ESCALATIONS_REQUEST: "GET_ALL_ESCALATIONS_REQUEST",
    GET_ALL_ESCALATIONS_SUCCESS: "GET_ALL_ESCALATIONS_SUCCESS",
    GET_ALL_ESCALATIONS_FAILURE: "GET_ALL_ESCALATIONS_FAILURE",

    GET_ALL_LOCATIONS_REQUEST: "GET_ALL_LOCATIONS_REQUEST",
    GET_ALL_LOCATIONS_SUCCESS: "GET_ALL_LOCATIONS_SUCCESS",
    GET_ALL_LOCATIONS_FAILURE: "GET_ALL_LOCATIONS_FAILURE",

    ADD_NEW_ESCALATIONS_REQUEST: "ADD_NEW_ESCALATIONS_REQUEST",
    ADD_NEW_ESCALATIONS_SUCCESS: "ADD_NEW_ESCALATIONS_SUCCESS",
    ADD_NEW_ESCALATIONS_FAILURE: "ADD_NEW_ESCALATIONS_FAILURE",

    UPDATE_ESCALATION_REQUEST: "UPDATE_ESCALATION_REQUEST",
    UPDATE_ESCALATION_SUCCESS: "UPDATE_ESCALATION_SUCCESS",
    UPDATE_ESCALATION_FAILURE: "UPDATE_ESCALATION_FAILURE",

    DELETE_ESCALATION_REQUEST: "DELETE_ESCALATION_REQUEST",
    DELETE_ESCALATION_SUCCESS: "DELETE_ESCALATION_SUCCESS",
    DELETE_ESCALATION_FAILURE: "DELETE_ESCALATION_FAILURE"
};

