import React, {Component} from "react";
import MaterialTable from "material-table";
import {connect} from "react-redux";
import {NavigationBar} from "../Components/NavigationBar";
import {tagsAction} from "../_actions";
import MDSpinner from "react-md-spinner";
import {history} from "../_helpers";
import * as ExcelJS from "exceljs/dist/exceljs";
import {saveAs} from "file-saver";

import {batteryEmpty} from "react-icons-kit/ionicons/batteryEmpty";
import {batteryFull} from "react-icons-kit/ionicons/batteryFull";
import {batteryHalf} from "react-icons-kit/ionicons/batteryHalf";
import {batteryLow} from "react-icons-kit/ionicons/batteryLow";

import {Icon} from "react-icons-kit";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

import "./PWTagsPage.css";

class PWTagsPage extends Component {
  constructor(props) {
    super(props);
    TimeAgo.addLocale(en);
    this.state = {
      data: {},
      pageSize: 50
    };
  }

  componentDidMount() {
    if (localStorage.getItem("selected_customer") == null) {
      history.push("/login");
    } else {
      this.props.dispatch(tagsAction.getAllPWTags());
    }
  }

  componentWillReceiveProps(nextProps) {
    const tags = nextProps.tags.items;
    if (tags && tags.length > 0) {
      const timeAgo = new TimeAgo("en-US");

      let listItems = tags.map((tag, i) => ({
        serialnum: tag.serialnum,
        macaddrble: tag.macaddrble && tag.macaddrble.toLowerCase(),
        location: tag.Location,
        battery: tag.TagBattery,
        timestamp: tag.TimeStamp
      }));

      let data = {
        columns: [
          {
            title: "Serial Number",
            field: "serialnum",
            editable: "never",
            cellStyle: {
              fontSize: 13
            }
          },
          {
            title: "Mac Address",
            field: "macaddrble",
            editable: "never",
            cellStyle: {
              fontSize: 13
            }
          },
          {
            title: "Battery",
            field: "battery",
            editable: "never",
            render: rowData => (
              <div
                style={{
                  width: 40,
                  height: 40,
                  color:
                    rowData.battery < 1000
                      ? "black"
                      : rowData.battery >= 1000 && rowData.battery < 2500
                      ? "red"
                      : rowData.battery >= 2500 && rowData.battery < 2760
                      ? "orange"
                      : rowData.battery >= 2760 && rowData.battery < 2830
                      ? "dodgerblue"
                      : "green"
                }}
              >
                <Icon
                  size={"100%"}
                  icon={
                    rowData.battery <= 1000
                      ? batteryEmpty
                      : rowData.battery >= 1000 && rowData.battery < 2500
                      ? batteryReplace
                      : rowData.battery > 2500 && rowData.battery < 2760
                      ? batteryLow
                      : rowData.battery >= 2760 && rowData.battery < 2830
                      ? batteryHalf
                      : batteryFull
                  }
                />
              </div>
            ),
            cellStyle: {
              fontSize: 13
            }
          },
          {
            title: "Last Location",
            field: "location",
            editable: "never",
            cellStyle: {
              fontSize: 13
            }
          },
          {
            title: "Last Updated",
            field: "timestamp",
            editable: "never",
            render: rowData =>
              rowData.timestamp != null ? (
                <div>
                  <span>
                    {timeAgo.format(new Date(rowData.timestamp * 1000), "time")}
                  </span>
                </div>
              ) : (
                "N/A"
              ),
            cellStyle: {
              fontSize: 13
            }
          }
        ],
        rows: listItems
      };
      this.setState(function(prevState, props) {
        return { data: data };
      });
    }
  }

  render() {
    let tags = this.state.data;

    let pageSize = this.state.pageSize;
    return (
      <div>
        <NavigationBar />
        <div className="table-style">
          {Object.keys(tags).length === 0 && tags.constructor === Object && (
            <MDSpinner className="loader-style" />
          )}
          {Object.keys(tags).length > 0 && tags.constructor === Object && (
            <MaterialTable
              localization={{
                toolbar: {
                  searchPlaceholder: "Search",
                  exportName: "Export to Excel"
                },
                body: {
                  deleteTooltip: "Delete",
                  emptyDataSourceMessage: "No data to display"
                },
                pagination: {
                  labelRowsSelect: "Rows"
                }
              }}
              columns={tags.columns}
              data={tags.rows}
              title="PW TAGS"
              options={{
                headerStyle: {
                  backgroundColor: "#32669a",
                  color: "#FFF",
                  fontSize: 15
                },
                showTitle: true,
                actionsColumnIndex: -1,
                pageSize: pageSize,
                pageSizeOptions: [10, 25, 50, 100],
                searchFieldStyle: {
                  backgroundColor: "#ff",
                  color: "#32669a",
                  fontSize: 18,
                  borderColor: "#32669a"
                },
                paginationType: "stepped",
                showEmptyDataSourceMessage: true,
                exportButton: true,
                exportAllData: true,
                exportCsv: (columns, data) => {
                  saveAsExcel(columns, data);
                }
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    let updateTag = {
                      serialnum: newData.serialnum,
                      Location: newData.location
                    };
                    this.props.dispatch(tagsAction.saveTag(updateTag));

                    let index = tags.rows.indexOf(oldData);
                    tags.rows[index] = newData;
                    this.setState = {
                      data: tags
                    };
                    resolve();
                  })
              }}
              onChangeRowsPerPage={event => {
                this.setState({ pageSize: event });
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

async function saveAsExcel(columns, data) {
  const wb = new ExcelJS.Workbook();

  const ws = wb.addWorksheet();
  ws.columns = [
    { header: "Serial Number", key: "serialnum", width: 20 },
    { header: "Location", key: "gojoLocationName", width: 20 },
    { header: "Battery", key: "battery", width: 20 },
    { header: "Last Updated", key: "timestamp", width: 20 }
  ];

  data.forEach(element => {
    let batteryInt =
      element.battery != undefined ? parseInt(element.battery) / 1000 : 0;
    if (batteryInt > 0) {
      const rounded = Math.round(batteryInt * 10) / 10;
      batteryInt = rounded.toString() + "V";
    } else {
      batteryInt = "";
    }
    let dateString = "";
    if (element.timestamp != undefined) {
      const date = new Date(element.timestamp * 1000);
      let hour = ((date.getHours() + 24) % 12 || 12).toString();
      let minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
      let dateStrin1 = hour
        .concat(":")
        .concat(minutes)
        .concat(date.getHours() >= 12 ? "PM" : "AM")
        .concat(" ");
      dateString =
        dateStrin1 +
        date.getMonth().toString() +
        "/" +
        date.getDate().toString() +
        "/" +
        date.getFullYear().toString();
    }

    const row = ws.addRow([
      element.serialnum,
      element.location,
      batteryInt,
      dateString
    ]);
    row.font = { bold: true };
  });

  const buf = await wb.xlsx.writeBuffer();
  saveAs(new Blob([buf]), "PWTags.xlsx");
}

function mapStateToProps(state) {
  const { tags } = state;
  return {
    tags
  };
}

const connectedTagsPage = connect(mapStateToProps)(PWTagsPage);
export { connectedTagsPage as PWTagsPage };
