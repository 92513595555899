import React, {Component} from "react";
import {connect} from "react-redux";
import {NavigationBar} from "../Components/NavigationBar";
import {gatewayAction, receiversAction} from "../_actions";
import MDSpinner from "react-md-spinner";
import MaterialTable from "material-table";
import "./ReceiversPage.css";
import {history} from "../_helpers";
import Select from 'react-dropdown-select';

import * as ExcelJS from "exceljs/dist/exceljs";
import {saveAs} from "file-saver";

import {batteryEmpty} from "react-icons-kit/ionicons/batteryEmpty";
import {batteryFull} from "react-icons-kit/ionicons/batteryFull";
import {batteryHalf} from "react-icons-kit/ionicons/batteryHalf";
import {batteryLow} from "react-icons-kit/ionicons/batteryLow";

import {Icon} from "react-icons-kit";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

class ReceiversPage extends Component {
    constructor(props) {
        super(props);
        this.loadReceivers = this.loadReceivers.bind(this);
        this.selectedGateway = null;
        TimeAgo.addLocale(en);
        this.state = {
            data: {},
            gateways: {},
            pageSize: 50
        };
    }

    componentDidMount() {
        if (localStorage.getItem("selected_customer") == null) {
            history.push("/login");
        } else {
            this.props.dispatch(gatewayAction.getAllGateways());
            setTimeout(this.loadReceivers(), 2000);
        }
    }

    loadReceivers() {
        this.props.dispatch(receiversAction.getAllReceivers());
    }

    onGatewayChange(values) {
        if (values.length > 0) {
            this.selectedGateway = values[0].name;
        }
        console.log(this.selectedGateway);
    }

    componentWillReceiveProps(nextProps) {
        const gateways = nextProps.gateways.items;
        const receivers = nextProps.receivers.items;
        const timeAgo = new TimeAgo("en-US");

        let newGateways = {};

        if (gateways && gateways.length > 0) {
            newGateways = gateways.map((currElement, index) => {
                return {id: index + 1, name: currElement.serialnum};
            });
        }
        if (receivers && receivers.length > 0) {
            let listItems = receivers.map((receiver, i) => ({
                serialnum: receiver.serialnum,
                macaddress: receiver.macaddrble && receiver.macaddrble.toLowerCase(),
                gateway: receiver.gateway,
                sensorType:
                    receiver.sensorType === "Ceiling"
                        ? 1
                        : receiver.sensorType === "Hallway"
                            ? 2
                            : 0,
                locationName: receiver.locationName,
                timestamp: receiver.TimeStamp,
                battery: receiver.SensorBattery,
                smdcode: receiver.SMDCode,
                status: receiver.TimeStamp != null ? (new Date().valueOf() - new Date(receiver.TimeStamp * 1000)) / 1000 <= 1800 ?
                    "Online" : "Offline" : "Offline",
            }));

            let data = {
                columns: [
                    {
                        title: "Serial Number",
                        field: "serialnum",
                        editable: "never",
                        cellStyle: {
                            fontSize: 13
                        }
                    },
                    {
                        title: "MAC Address",
                        field: "macaddress",
                        editable: "never",
                        cellStyle: {
                            fontSize: 13
                        }
                    },
                    {
                        title: "Gateway",
                        field: "gateway",
                        editComponent: props => (
                            <Select
                                placeholder={props.rowData.gateway}
                                options={newGateways}
                                labelField={"name"}
                                searchable={"name"}
                                searchBy={"name"}
                                onChange={(values) => this.onGatewayChange(values)}
                            />
                        ),
                        cellStyle: {
                            fontSize: 13
                        }
                    },
                    {
                        title: "Sensor Type",
                        field: "sensorType",
                        lookup: {1: "Ceiling", 2: "Hallway"},
                        cellStyle: {
                            fontSize: 13
                        }
                    },
                    {
                        title: "Dial Position",
                        field: "smdcode",
                        editable: "never",
                        cellStyle: {
                            fontSize: 13
                        }
                    },
                    {
                        title: "Battery",
                        field: "battery",
                        editable: "never",
                        render: rowData => (
                            <div
                                style={{
                                    width: 40,
                                    height: 40,
                                    color:
                                        rowData.battery < 1000
                                            ? "black"
                                            : rowData.battery >= 1000 && rowData.battery < 2500
                                                ? "red"
                                                : rowData.battery >= 2500 && rowData.battery < 2760
                                                    ? "orange"
                                                    : rowData.battery >= 2760 && rowData.battery < 2830
                                                        ? "dodgerblue"
                                                        : "green"
                                }}
                            >
                                <Icon
                                    size={"100%"}
                                    icon={
                                        rowData.battery <= 1000
                                            ? batteryEmpty
                                            : rowData.battery >= 1000 && rowData.battery < 2500
                                                ? batteryEmpty
                                                : rowData.battery > 2500 && rowData.battery < 2760
                                                    ? batteryLow
                                                    : rowData.battery >= 2760 && rowData.battery < 2830
                                                        ? batteryHalf
                                                        : batteryFull
                                    }
                                />
                            </div>
                        ),
                        cellStyle: {
                            fontSize: 13
                        }
                    },
                    {
                        title: "Location",
                        field: "locationName",
                        cellStyle: {
                            fontSize: 13
                        }
                    },
                    {
                        title: "Last Updated",
                        field: "timestamp",
                        editable: "never",
                        render: rowData =>
                            rowData.timestamp != null ? (
                                <div>
                  <span>
                    {timeAgo.format(new Date(rowData.timestamp * 1000), "time")}
                  </span>
                                </div>
                            ) : (
                                "N/A"
                            ),
                        cellStyle: {
                            fontSize: 13
                        }
                    },
                    {
                        title: "Status",
                        field: "status",
                        editable: "never",
                        render: rowData => rowData.timestamp != null ? (new Date().valueOf() - new Date(rowData.timestamp * 1000)) / 1000 <= 1800 ?
                                <span className="online">Online</span> : <span className="offline">Offline</span> :
                            <span className="offline">Offline</span>,
                        cellStyle: {
                            fontSize: 13
                        }
                    }
                ],
                rows: listItems
            };
            this.setState(function (prevState, props) {
                return {
                    gateways: newGateways,
                    data: data
                };
            });
        }
    }

    render() {
        let receivers = this.state.data;
        let pageSize = this.state.pageSize;
        return (
            <div>
                <NavigationBar/>
                <div className="table-style">
                    {Object.keys(receivers).length === 0 &&
                    receivers.constructor === Object && (
                        <MDSpinner className="loader-style"/>
                    )}
                    {Object.keys(receivers).length > 0 &&
                    receivers.constructor === Object && (
                        <MaterialTable
                            localization={{
                                toolbar: {
                                    searchPlaceholder: "Search",
                                    exportName: "Export to Excel"
                                },
                                body: {
                                    deleteTooltip: "Delete"
                                },
                                pagination: {
                                    labelRowsSelect: "Rows"
                                }
                            }}
                            columns={receivers.columns}
                            data={receivers.rows}
                            title="RECEIVERS"
                            options={{
                                headerStyle: {
                                    backgroundColor: "#32669a",
                                    color: "#FFF",
                                    fontSize: 15
                                },
                                showTitle: true,
                                actionsColumnIndex: -1,
                                pageSize: pageSize,
                                pageSizeOptions: [10, 25, 50, 100],
                                searchFieldStyle: {
                                    backgroundColor: "#ff",
                                    color: "#32669a",
                                    fontSize: 18,
                                    borderColor: "#32669a"
                                },
                                paginationType: "stepped",
                                showEmptyDataSourceMessage: true,
                                exportButton: true,
                                exportAllData: true,
                                sorting: true,
                                exportCsv: (columns, data) => {
                                    saveAsExcel(columns, data).then(r => {
                                    });
                                }
                            }}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        let sensorType =
                                            newData.sensorType === "1"
                                                ? "Ceiling"
                                                : newData.sensorType === "2"
                                                    ? "Hallway"
                                                    : oldData.sensorType === 1
                                                        ? "Ceiling"
                                                        : oldData.sensorType === 2
                                                            ? "Hallway"
                                                            : "";
                                        let rssiValue = "-69";

                                        let updateReceiver = {
                                            serialnum: newData.serialnum,
                                            macaddrble: newData.macaddress,
                                            gateway:
                                                this.selectedGateway != null
                                                    ? this.selectedGateway
                                                    : newData.gateway,
                                            sensorType: sensorType,
                                            rssi: rssiValue,
                                            locationName: newData.locationName
                                        };
                                        this.props.dispatch(
                                            receiversAction.saveReceiver(updateReceiver)
                                        );
                                        newData.gateway = this.selectedGateway != null
                                            ? this.selectedGateway
                                            : newData.gateway;
                                        this.selectedGateway = null;

                                        let index = receivers.rows.indexOf(oldData);
                                        receivers.rows[index] = newData;
                                        this.setState = {
                                            data: receivers
                                        };
                                        resolve();
                                    })
                            }}
                            onChangeRowsPerPage={event => {
                                this.setState({pageSize: event});
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }
}

async function saveAsExcel(columns, data) {
    const wb = new ExcelJS.Workbook();

    const ws = wb.addWorksheet();
    ws.columns = [
        {header: "Serial Number", key: "serialnum", width: 20},
        {header: "Mac Address", key: "macaddress", width: 20},
        {header: "Gateway", key: "gateway", width: 20},
        {header: "Sensor Type", key: "sensorType", width: 20},
        {header: "Location", key: "locationName", width: 20},
        {header: "Dial Position", key: "smdcode", width: 20},
        {header: "Battery", key: "battery", width: 20},
        {header: "Last Updated", key: "timestamp", width: 20}
    ];

    data.forEach(element => {
        let smdcode = element.smdcode !== undefined ? parseInt(element.smdcode) : 0;

        let batteryInt =
            element.battery !== undefined ? parseInt(element.battery) / 1000 : 0;
        if (batteryInt > 0) {
            var rounded = Math.round(batteryInt * 10) / 10;
            batteryInt = rounded.toString() + "V";
        } else {
            batteryInt = "";
        }
        let dateString = "";
        if (element.timestamp !== undefined) {
            const date = new Date(element.timestamp * 1000);
            let hour = ((date.getHours() + 24) % 12 || 12).toString();
            let minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
            let dateStrin1 = hour
                .concat(":")
                .concat(minutes)
                .concat(date.getHours() >= 12 ? "PM" : "AM")
                .concat(" ");
            dateString =
                dateStrin1 +
                date.getMonth().toString() +
                "/" +
                date.getDate().toString() +
                "/" +
                date.getFullYear().toString();
        }

        ws.addRow([
            element.serialnum,
            element.macaddress,
            element.gateway,
            element.sensorType === 1
                ? "Ceiling"
                : element.sensorType === 2
                    ? "Hallway"
                    : "",
            element.locationName,
            smdcode,
            batteryInt,
            dateString
        ]);
    });

    const buf = await wb.xlsx.writeBuffer();
    saveAs(new Blob([buf]), "Receivers.xlsx");
}

function mapStateToProps(state) {
    const {receivers, gateways} = state;
    return {
        receivers,
        gateways
    };
}

const connectedReceiversPage = connect(mapStateToProps)(ReceiversPage);
export {connectedReceiversPage as ReceiversPage};
