import React, {Component} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import queryString from 'query-string';
import "./ResetConfirmPasswordPage.css";

import {userActions} from "../_actions";

class ResetConfirmPasswordPage extends Component {
    constructor(props) {
        super(props);

        let params = queryString.parse(this.props.location.search)

        this.state = {
            user: {
                email: params.email,
                token: params.token,
                defaultPassword: params.defaultPassword,
                newpassword: "",
                confirmpassword: ""
            },
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const {name, value} = event.target;
        const {user} = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({submitted: true});
        const {user} = this.state;
        const {dispatch} = this.props;
        if (
            user.newpassword &&
            user.confirmpassword &&
            user.email &&
            (user.token || user.defaultPassword)
        ) {
            dispatch(userActions.confirmPassword(user));
        }
    }

    render() {
        const {authentication} = this.props;
        const {user, submitted} = this.state;
        return (
            <div className="col-md-6 col-md-offset-3">
                <h2>Reset Password</h2>
                <form name="form" onSubmit={this.handleSubmit}>
                    <div
                        className={
                            "form-group" + (submitted && !user.firstName ? " has-error" : "")
                        }
                    >
                        <label htmlFor="newpassword">New Password</label>
                        <input
                            type="password"
                            className="form-control"
                            name="newpassword"
                            value={user.newpassword}
                            onChange={this.handleChange}
                        />
                        {submitted && !user.newpassword && (
                            <div className="help-block">New Password is required</div>
                        )}
                    </div>
                    <div
                        className={
                            "form-group" + (submitted && !user.lastName ? " has-error" : "")
                        }
                    >
                        <label htmlFor="confirmpassword">Confirm Password</label>
                        <input
                            type="password"
                            className="form-control"
                            name="confirmpassword"
                            value={user.confirmpassword}
                            onChange={this.handleChange}
                        />
                        {submitted && !user.confirmpassword && (
                            <div className="help-block">Confirm Password is required</div>
                        )}
                    </div>
                    <div className="form-group">
                        <button className="btn btn-primary">Submit</button>
                        {authentication && (
                            <img
                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
                        )}
                        <Link to="/login" className="btn btn-link">
                            Back
                        </Link>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {authentication} = state.authentication;
    return {
        authentication
    };
}

const connectedRegisterPage = connect(mapStateToProps)(
    ResetConfirmPasswordPage
);

export {connectedRegisterPage as ResetConfirmPasswordPage};
