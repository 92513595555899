import React, {Component} from "react";
import {connect} from "react-redux";
import {NavigationBar} from "../Components";
import "./SiteSettingsPage.css";
import {history} from "../_helpers";
import {siteSettingsAction} from "../_actions";
import ListGroup from "react-bootstrap/ListGroup";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import MDSpinner from "react-md-spinner";

class SiteSettingsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null
        }
    }

    componentDidMount() {
        if (localStorage.getItem("selected_customer") == null) {
            history.push("/login");
        } else {
            this.props.dispatch(siteSettingsAction.getSiteSettings());
        }
    }

    componentWillReceiveProps(nextProps) {
        const sitesettings = nextProps.siteSettings.items;
        if (sitesettings && sitesettings.length > 0) {
            this.setState(function (prevState, props) {
                return {data: sitesettings};
            });
        }
    }

    render() {
        const NewStyledButton = withStyles({
            root: {
                background: 'linear-gradient(45deg, #ef6b22 30%, #ef6b22 90%)',
                borderRadius: 3,
                border: 0,
                color: 'white',
                height: 48,
                fontSize: 22,
                padding: '0 30px',
                boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            },
            label: {
                textTransform: 'capitalize',
            },
        })(Button);

        let siteSettings = this.state.data;

        return (
            <div>
                <NavigationBar/>
                <ListGroup>
                    {siteSettings == null && (
                        <MDSpinner className="loader-style"/>
                    )}
                    {siteSettings && siteSettings.map(item =>
                        <div>
                            <ListGroup.Item
                                key={item.name}>{item.name}

                            </ListGroup.Item>
                        </div>

                    )}
                    <NewStyledButton style={{marginTop: '20px'}} href="/createsitesettings">Add New</NewStyledButton>
                </ListGroup>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const {siteSettings} = state;
    return {
        siteSettings
    };
}

const connectedSettingsPage = connect(mapStateToProps)(SiteSettingsPage);
export {connectedSettingsPage as SiteSettingsPage};
