import {alertActions} from "./alert.actions";
import {homeService} from "../_services";
import {homeConstants} from "../_constants";

export const homeAction = {
    getDashbaordData
};

function getDashbaordData() {
    return dispatch => {
        dispatch(request({}));
        homeService.getDashbaord().then(
            dashboardData => {
                dispatch(success(dashboardData));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return {type: homeConstants.GET_DASHBOARD_REQUEST};
    }

    function success(dashboardData) {
        return {
            type: homeConstants.GET_DASHBOARD_REQUEST_SUCCESS,
            dashboardData
        };
    }

    function failure(error) {
        return {
            type: homeConstants.GET_DASHBOARD_REQUEST_FAILURE,
            error
        };
    }
}
