import React, {Component} from "react";
import {connect} from "react-redux";
import {NavigationBar} from "../Components/NavigationBar";
import {gatewayAction, siteSettingsAction} from "../_actions";
import MDSpinner from "react-md-spinner";
import MaterialTable from "material-table";
import {history} from "../_helpers";
import * as ExcelJS from "exceljs/dist/exceljs";
import {saveAs} from "file-saver";

import "./GatewaysPage.css";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

class GatewaysPage extends Component {
    constructor(props) {
        super(props);
        TimeAgo.addLocale(en);
        this.state = {
            data: {},
            pageSize: 50,
            sitesettings: {}
        };
    }

    componentDidMount() {
        if (localStorage.getItem("selected_customer") == null) {
            history.push("/login");
        } else {
            // #### For Future Feature SiteSettings ####
            //this.props.dispatch(siteSettingsAction.getSiteSettings());
            //setTimeout(this.load_gateways_data(), 2000);
            this.props.dispatch(gatewayAction.getAllGateways());
        }
    }

    componentWillReceiveProps(nextProps) {
        const gateways = nextProps.gateways.items;

        if (nextProps.siteSettings && nextProps.siteSettings.items != null) {
            const sitesettings = nextProps.siteSettings.items;

            if (sitesettings && sitesettings.length > 0) {
                const settingsArray = {};
                sitesettings.forEach(function (value, i) {
                    settingsArray[i] = value.name
                });

                this.setState(function (prevState, props) {
                    return {siteSettings: settingsArray};
                });
            }
        }

        function getKeyByValue(object, value) {
            return Object.keys(object).find(key => object[key] === value);
        }

        if (gateways && gateways.length > 0) {
            const timeAgo = new TimeAgo("en-US");

            let listItems = gateways.map((gateway, i) => ({
                serialnum: gateway.serialnum,
                macaddreth: gateway.macaddreth && gateway.macaddreth.toLowerCase(),
                locationName: gateway.locationName,
                // #### For Future Feature SiteSettings ####
                // sitesettings: getKeyByValue(this.state.siteSettings, gateway.siteSettings),
                unitName: gateway.unitName,
                status: gateway.isOnline,
                timestamp: gateway.TimeStamp
            }));

            let data = {
                columns: [
                    {
                        title: "Serial Number",
                        field: "serialnum",
                        editable: "never",
                        cellStyle: {
                            fontSize: 13
                        }
                    },
                    {
                        title: "Mac Address",
                        field: "macaddreth",
                        editable: "never",
                        cellStyle: {
                            fontSize: 13
                        }
                    },
                    {
                        title: "Unit Name",
                        field: "unitName",
                        cellStyle: {
                            fontSize: 13
                        }
                    },
                    {
                        title: "Location Name",
                        field: "locationName",
                        cellStyle: {
                            fontSize: 13
                        }
                    },
                    {
                        title: "Last Updated",
                        field: "timestamp",
                        editable: "never",
                        render: rowData =>
                            rowData.timestamp != null ? (
                                <div>
                                    <span>
                                        {timeAgo.format(new Date(rowData.timestamp * 1000), "time")}
                                    </span>
                                </div>
                            ) : (
                                "N/A"
                            ),
                        cellStyle: {
                            fontSize: 13
                        }
                    },
                    {
                        title: "Status",
                        field: "status",
                        editable: "never",
                        render: rowData =>
                            rowData.status == true ? (
                                <div>
                                    <span className="online">Online</span>
                                </div>
                            ) : (
                                <span className="offline">Offline</span>
                            ),
                        cellStyle: {
                            fontSize: 13
                        }
                    }
                ],
                rows: listItems
            };
            this.setState(function (prevState, props) {
                return {data: data};
            });
        }
    }

    render() {
        let gateways = this.state.data;
        let pageSize = this.state.pageSize;
        return (
            <div>
                <NavigationBar/>
                <div className="table-style">
                    {Object.keys(gateways).length === 0 &&
                    gateways.constructor === Object && (
                        <MDSpinner className="loader-style"/>
                    )}
                    {Object.keys(gateways).length > 0 && gateways.constructor === Object && (
                        <MaterialTable
                            localization={{
                                toolbar: {
                                    searchPlaceholder: "Search",
                                    exportName: "Export to Excel"
                                },
                                body: {
                                    deleteTooltip: "Delete",
                                    emptyDataSourceMessage: "No data to display"
                                },
                                pagination: {
                                    labelRowsSelect: "Rows"
                                }
                            }}
                            columns={gateways.columns}
                            data={gateways.rows}
                            title="GATEWAYS"
                            options={{
                                headerStyle: {
                                    backgroundColor: "#32669a",
                                    color: "#FFF",
                                    fontSize: 15
                                },
                                showTitle: true,
                                actionsColumnIndex: -1,
                                pageSize: pageSize,
                                pageSizeOptions: [10, 25, 50, 100],
                                searchFieldStyle: {
                                    backgroundColor: "#ff",
                                    color: "#32669a",
                                    fontSize: 18,
                                    borderColor: "#32669a"
                                },
                                paginationType: "stepped",
                                showEmptyDataSourceMessage: true,
                                exportButton: true,
                                exportAllData: true,
                                exportCsv: (columns, data) => {
                                    saveAsExcel(columns, data);
                                }
                            }}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        console.log(newData.sitesettings);

                                        let updateGateway = {
                                            serialnum: newData.serialnum,
                                            locationName: newData.locationName,
                                            unitName: newData.unitName
                                        };

                                        // #### For Future Feature SiteSettings ####
                                        // if (newData.sitesettings != undefined) {
                                        //     updateGateway["siteSettings"] = this.state.siteSettings[newData.sitesettings]
                                        // }
                                        this.props.dispatch(
                                            gatewayAction.saveGateway(updateGateway)
                                        );

                                        let index = gateways.rows.indexOf(oldData);
                                        gateways.rows[index] = newData;
                                        this.setState = {
                                            data: gateways
                                        };
                                        resolve();
                                    })
                            }}
                            onChangeRowsPerPage={event => {
                                this.setState({pageSize: event});
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }
}

async function saveAsExcel(columns, data) {
    const wb = new ExcelJS.Workbook();

    const ws = wb.addWorksheet();
    ws.columns = [
        {header: "Serial Number", key: "serialnum", width: 20},
        {header: "Mac Address", key: "macaddreth", width: 20},
        {header: "Location Name", key: "locationName", width: 20},
        {header: "Location Name", key: "unitName", width: 20},
        {header: "Status", key: "status", width: 15}
    ];
    data.forEach(element => {
        let status = element.status == true ? "online" : "offline";
        ws.addRow([
            element.serialnum,
            element.macaddreth,
            element.locationName,
            element.unitName,
            status
        ]);
    });

    const buf = await wb.xlsx.writeBuffer();
    saveAs(new Blob([buf]), "Gateways.xlsx");
}

function mapStateToProps(state) {
    const {gateways, siteSettings} = state;
    return {
        gateways,
        siteSettings
    };
}

const connectedGatewaysPage = connect(mapStateToProps)(GatewaysPage);
export {connectedGatewaysPage as GatewaysPage};
