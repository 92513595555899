import {receiversConstants} from "../_constants";

export function receivers(state = {}, action) {
    switch (action.type) {
        case receiversConstants.GET_ALL_RECEIVERS_REQUEST:
            return {
                loading: true
            };
        case receiversConstants.GET_ALL_RECEIVERS_REQUEST_SUCCESS:
            return {
                loading: false,
                items: action.receivers
            };
        case receiversConstants.GET_ALL_RECEIVERS_REQUEST_FAILURE:
            return {
                loading: false,
                error: action.error
            };
        case receiversConstants.UPDATE_RECEIVERS_REQUEST:
            return {
                isSavingData: false
            };
        case receiversConstants.UPDATE_RECEIVERS_REQUEST_SUCCESS:
            return {
                isSavingData: true,
                response: action.response
            };
        case receiversConstants.UPDATE_RECEIVERS_REQUEST_FAILURE:
            return {
                isSavingData: false,
                error: action.error
            };
        default:
            return state;
    }
}
