import { escalationsConstants} from "../_constants";
import {alertActions} from "./";
import { escalationsService} from "../_services";

export const escalationsAction = {
    getAllEscalations,
    getAllLocations,
    saveEscalation,
    updateEscalation,
    deleteEscalation
};

function getAllEscalations() {
    return dispatch => {
        dispatch(request({}));
        escalationsService.getAllEscalations().then(
            unitNames => {
                dispatch(success(unitNames));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return {type: escalationsConstants.GET_ALL_ESCALATIONS_REQUEST};
    }

    function success(escalations) {
        return {
            type: escalationsConstants.GET_ALL_ESCALATIONS_SUCCESS,
            escalations
        };
    }

    function failure(error) {
        return {
            type: escalationsConstants.GET_ALL_ESCALATIONS_FAILURE,
            error
        };
    }
}

function getAllLocations() {
    return dispatch => {
        dispatch(request({}));
        escalationsService.getAllLocations().then(
            locations => {
                dispatch(success(locations));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return {type: escalationsConstants.GET_ALL_LOCATIONS_REQUEST};
    }

    function success(locations) {
        return {
            type: escalationsConstants.GET_ALL_LOCATIONS_SUCCESS,
            locations
        };
    }

    function failure(error) {
        return {
            type: escalationsConstants.GET_ALL_LOCATIONS_FAILURE,
            error
        };
    }
}

function saveEscalation(data) {
    return dispatch => {
        dispatch(request({}));
        escalationsService.saveEscalation(data).then(
            response => {
                dispatch(success(response));
                dispatch(alertActions.success(response.message));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return {type: escalationsConstants.ADD_NEW_ESCALATIONS_REQUEST};
    }

    function success(response) {
        return {
            type: escalationsConstants.ADD_NEW_ESCALATIONS_SUCCESS,
            response
        };
    }

    function failure(error) {
        return {
            type: escalationsConstants.ADD_NEW_ESCALATIONS_FAILURE,
            error
        };
    }
}

function updateEscalation(name, data) {
    return dispatch => {
        dispatch(request({}));
        escalationsService.updateEscalation(name, data).then(
            response => {
                dispatch(success(response));
                dispatch(alertActions.success(response.message));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return {type: escalationsConstants.UPDATE_ESCALATION_REQUEST};
    }

    function success(response) {
        return {
            type: escalationsConstants.UPDATE_ESCALATION_SUCCESS,
            response
        };
    }

    function failure(error) {
        return {
            type: escalationsConstants.UPDATE_ESCALATION_FAILURE,
            error
        };
    }
}

function deleteEscalation(name) {
    return dispatch => {
        dispatch(request({}));
        escalationsService.deleteEscalation(name).then(
            response => {
                dispatch(success(response));
                dispatch(alertActions.success(response.message));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return {type: escalationsConstants.ADD_NEW_ESCALATIONS_REQUEST};
    }

    function success(response) {
        return {
            type: escalationsConstants.ADD_NEW_ESCALATIONS_SUCCESS,
            response
        };
    }

    function failure(error) {
        return {
            type: escalationsConstants.ADD_NEW_ESCALATIONS_FAILURE,
            error
        };
    }
}