import {tagsConstants} from "../_constants";
import {tagsService} from "../_services";
import {alertActions} from "./";

export const tagsAction = {
    getAllStaffTags,
    getAllAssetTags,
    getAllPWTags,
    saveTag
};

function getAllStaffTags() {
    return dispatch => {
        dispatch(request({}));
        tagsService.getAllStaffTags().then(
            tags => {
                dispatch(success(tags));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return {type: tagsConstants.GET_ALL_TAGS_REQUEST};
    }

    function success(tags) {
        return {
            type: tagsConstants.GET_ALL_TAGS_REQUEST_SUCCESS,
            tags
        };
    }

    function failure(error) {
        return {
            type: tagsConstants.GET_ALL_TAGS_REQUEST_FAILURE,
            error
        };
    }
}

function getAllAssetTags() {
    return dispatch => {
        dispatch(request({}));
        tagsService.getAllAssetTags().then(
            tags => {
                dispatch(success(tags));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return {type: tagsConstants.GET_ALL_TAGS_REQUEST};
    }

    function success(tags) {
        return {
            type: tagsConstants.GET_ALL_TAGS_REQUEST_SUCCESS,
            tags
        };
    }

    function failure(error) {
        return {
            type: tagsConstants.GET_ALL_TAGS_REQUEST_FAILURE,
            error
        };
    }
}

function getAllPWTags() {
    return dispatch => {
        dispatch(request({}));
        tagsService.getAllPWTags().then(
            tags => {
                dispatch(success(tags));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return {type: tagsConstants.GET_ALL_TAGS_REQUEST};
    }

    function success(tags) {
        return {
            type: tagsConstants.GET_ALL_TAGS_REQUEST_SUCCESS,
            tags
        };
    }

    function failure(error) {
        return {
            type: tagsConstants.GET_ALL_TAGS_REQUEST_FAILURE,
            error
        };
    }
}

function saveTag(tag) {
    return dispatch => {
        dispatch(request({}));
        tagsService.saveTag(tag).then(
            response => {
                dispatch(success(response));
                dispatch(alertActions.success(response.message));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return {type: tagsConstants.UPDATE_TAGS_REQUEST};
    }

    function success(response) {
        return {
            type: tagsConstants.UPDATE_TAGS_REQUEST_SUCCESS,
            response
        };
    }

    function failure(error) {
        return {
            type: tagsConstants.UPDATE_TAGS_REQUEST_FAILURE,
            error
        };
    }
}
