import React from "react";
import {BrowserRouter, Route} from 'react-router-dom';
import {connect} from "react-redux";

import {history} from "../_helpers";
import {alertActions} from "../_actions";
import {PrivateRoute} from "../_components";
import {HomePage} from "../HomePage";
import {LoginPage} from "../LoginPage";
import {RegisterPage} from "../RegisterPage";
import {ResetPasswordPage} from "../ResetPassword";
import {ResetConfirmPasswordPage} from "../ResetConfirmPassword";
import {StaffTagsPage} from "../StaffTagsPage";
import {AssetTagsPage} from "../AssetTagPage";
import {PWTagsPage} from "../PWTagePage";
import {ReceiversPage} from "../ReceiversPage";
import {GatewaysPage} from "../GatewaysPage";
import {DispensersPage} from "../DispensersPage";
import {AccountPage} from "../AccountPage";
import {CreateSiteSettingsPage} from "../CreateSiteSettings";
import {SiteSettingsPage} from "../SiteSettingsPage";
import {EscalationChainPage} from "../EscalationChain";

import "./App.css";

class App extends React.Component {
    constructor(props) {
        super(props);
        history.listen((location, action) => {
            this.props.clearAlerts();
        });
    }

    render() {
        const {alert} = this.props;
        return (
            <div className="container-style">
                {alert.message && (
                    <div className={`alert ${alert.type}`}>{alert.message}</div>
                )}
                <BrowserRouter>
                    <div>
                        <PrivateRoute exact path="/" component={HomePage}/>
                        <Route path="/login" component={LoginPage}/>
                        <Route path="/register" component={RegisterPage}/>
                        <Route path="/resetpassword" component={ResetPasswordPage}/>
                        <Route
                            exact
                            path="/confirmpassword/"
                            component={ResetConfirmPasswordPage}
                        />
                        <Route path="/stags" component={StaffTagsPage}/>
                        <Route path="/atags" component={AssetTagsPage}/>
                        <Route path="/pwtags" component={PWTagsPage}/>
                        <Route path="/rxs" component={ReceiversPage}/>
                        <Route path="/gateways" component={GatewaysPage}/>
                        <Route path="/dispensers" component={DispensersPage}/>
                        <Route path={"/sitesettings"} component={SiteSettingsPage}/>
                        <Route path={"/createsitesettings"} component={CreateSiteSettingsPage}/>
                        <Route path={"/escalations"} component={EscalationChainPage}/>
                        {/* <Route path="/livefeed" component={LiveFeedPage} /> */}
                        <Route path="/account" component={AccountPage}/>
                    </div>
                </BrowserRouter>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {alert} = state;
    return {
        alert
    };
}


const actionCreators = {
    clearAlerts: alertActions.clear
};

const connectedApp = connect(
    mapStateToProps,
    actionCreators
)(App);
export {connectedApp as App};