import React, {Component} from "react";
import {connect} from "react-redux";
import {NavigationBar} from "../Components";
import "./CreateSiteSettingsPage.css";
import Form from "react-bootstrap/Form";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import {siteSettingsAction} from "../_actions";


class CreateSiteSettingsPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            ethernet: false,
            wifi: false,
            dhcp: false,
            staticip: false,
            wifi_details: {
                ssid: "",
                password: "",
                security: ""
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeWifi = this.handleChangeWifi.bind(this);
        this.handleChangeDhcp = this.handleChangeDhcp.bind(this);
        this.handleChangeStaticIp = this.handleChangeStaticIp.bind(this);
        this.saveProfileToServer = this.saveProfileToServer.bind(this);
    }

    handleChange(event) {
        const ethernet = event.target.checked;
        const wifi = !ethernet;
        this.setState({
            ethernet: ethernet,
            wifi: wifi
        })
    }

    handleChangeWifi(event) {
        const wifi = event.target.checked;
        const ethernet = !wifi;
        this.setState({
            ethernet: ethernet,
            wifi: wifi
        })
    }

    handleChangeDhcp(event) {
        const dhcp = event.target.checked;
        const staticip = !dhcp;
        this.setState({
            dhcp: dhcp,
            staticip: staticip
        });
    }

    handleChangeStaticIp(event) {
        const staticip = event.target.checked;
        const dhcp = !staticip;
        this.setState({
            dhcp: dhcp,
            staticip: staticip
        });
    }

    saveProfileToServer() {
        let postData = {
            name: this.state.name,
            is_ethernet: this.state.ethernet,
            is_wifi: this.state.wifi,
            wifi_details: {
                ssid: this.state.wifi_details.ssid,
                password: this.state.wifi_details.password,
                security: this.state.wifi_details.security
            },
            is_dhcp: this.state.dhcp,
            is_staticip: this.state.staticip
        }
        this.props.dispatch(siteSettingsAction.createSiteSettings(postData));
    }

    render() {
        const SaveStyledButton = withStyles({
            root: {
                background: 'linear-gradient(45deg, #ef6b22 30%, #ef6b22 90%)',
                borderRadius: 3,
                border: 0,
                color: 'white',
                height: 48,
                fontSize: 22,
                padding: '0 30px',
                boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            },
            label: {
                textTransform: 'capitalize',
            },
        })(Button);

        const CancelStyledButton = withStyles({
            root: {
                background: 'linear-gradient(45deg, #32669a 30%, #32669a 90%)',
                borderRadius: 3,
                border: 0,
                color: 'white',
                height: 48,
                fontSize: 22,
                padding: '0 30px',
                boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
            },
            label: {
                textTransform: 'capitalize',
            },
        })(Button);

        return (
            <div>
                <NavigationBar/>
                <div className="container_style">
                    <span className={"title_style"}> Site Settings > Gateways</span>
                    <div className={"line_style"}/>
                    <div className={"profile_name_style"}>
                        <Form.Control value={this.state.name} placeholder="Profile Name" onChange={(event) => {
                            this.setState({
                                name: event.target.value
                            });
                        }}/>
                    </div>
                    <div className={"line_style_small"}/>
                    <span className={"sub_title_style"}> Network </span>
                    <div key={`ethernet-radio`} className={"radio_style"}>
                        <Form.Check
                            type='radio'
                            id={`ethernet-radio`}
                            checked={this.state.ethernet}
                            label={`Ethernet`}
                            onChange={this.handleChange}
                            style={{marginLeft: '20px'}}
                        />
                    </div>
                    <div key={`wifi-radio`}>
                        <div className={"wifi_box_style"}>
                            <Form.Check
                                type='radio'
                                id={`wifi-radio`}
                                checked={this.state.wifi}
                                onChange={this.handleChangeWifi}
                                style={{marginLeft: '20px'}}
                            />
                            <div className={"container_style_border"}>
                                <div className={"container_style_inner"}
                                     style={{backgroundColor: '#32669a'}}>
                                    <span className={'section_style'}> WiFi</span>
                                    <div className={'wifi_section_title'}>
                                        <span
                                            style={{color: 'white'}}>SSID:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <input value={this.state.wifi_details.ssid} className={'input_style'}
                                               onChange={(event) => {
                                                   this.setState({
                                                       wifi_details: {
                                                           ssid: event.target.value
                                                       }
                                                   });
                                               }}/>
                                    </div>
                                    <div className={'wifi_section_title'}>
                                        <span style={{color: 'white'}}>Password:&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <input value={this.state.wifi_details.password} className={'input_style'}
                                               onChange={(event) => {
                                                   this.setState({
                                                       wifi_details: {
                                                           password: event.target.value
                                                       }
                                                   });
                                               }}/>
                                    </div>
                                    <div className={'wifi_section_title'}>
                                        <span
                                            style={{color: 'white'}}> Security: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <select id="security_type" onChange={(event => {
                                            this.setState({
                                                wifi_details: {
                                                    security: event.target.value
                                                }
                                            });
                                        })}>
                                            <option value="none">None</option>
                                            <option value="wep">WEP</option>
                                            <option value="wpa/wep2">WPA/WPA2 Personal</option>
                                            <option value="wep2">WPA2 Personal</option>
                                            <option value="d_wep">Dynamic WEP</option>
                                            <option value="wpa_enter">WPA/WPA2 Enterprise</option>
                                            <option value="wpa2_enter">WPA2 Enterprise</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"ip_address_style"}>
                        <span className={"sub_title_style"}> IP Address </span>
                        <div key={`dhcp-radio`} className={"radio_style"}>
                            <Form.Check
                                type='radio'
                                id={`dhcp-radio`}
                                label={`DHCP`}
                                checked={this.state.dhcp}
                                onChange={this.handleChangeDhcp}
                                style={{marginLeft: '20px'}}
                            />
                        </div>
                        <div key={`static-radio`} className={"radio_style"}>
                            <Form.Check
                                type='radio'
                                id={`static-radio`}
                                label={`Static`}
                                checked={this.state.staticip}
                                onChange={this.handleChangeStaticIp}
                                style={{marginLeft: '20px'}}
                            />
                        </div>
                    </div>
                    <div className={'button_box_style'}>
                        <SaveStyledButton style={{marginTop: '30px'}} onClick={() => {
                            this.saveProfileToServer()
                        }}>Save</SaveStyledButton>
                        <CancelStyledButton style={{marginLeft: '30px', marginTop: '30px'}}
                                            href="/sitesettings">Cancel</CancelStyledButton>
                    </div>
                </div>
            </div>
        );
    }


}

function mapStateToProps(state) {
    // const {registering, authentication} = state;
    // return {
    //     registering,
    //     authentication
    // };
}

const connectedSettingsPage = connect(mapStateToProps)(CreateSiteSettingsPage);
export {connectedSettingsPage as CreateSiteSettingsPage};
