import React, { Component } from "react";
import { connect } from "react-redux";
import { NavigationBar } from "../Components/NavigationBar";
import { dispenserAction, gatewayAction, receiversAction } from "../_actions";
import MDSpinner from "react-md-spinner";
import MaterialTable from "material-table";
import { history } from "../_helpers";
import * as ExcelJS from "exceljs/dist/exceljs";
import { saveAs } from "file-saver";
import "./DispensersPage.css";

import { batteryEmpty } from "react-icons-kit/ionicons/batteryEmpty";
import { batteryFull } from "react-icons-kit/ionicons/batteryFull";
import { batteryHalf } from "react-icons-kit/ionicons/batteryHalf";
import { batteryLow } from "react-icons-kit/ionicons/batteryLow";

import { Icon } from "react-icons-kit";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import Select from "react-dropdown-select";

class DispensersPage extends Component {
    constructor(props) {
        super(props);
        this.loadDispensers = this.loadDispensers.bind(this);
        this.selectedGateway = null;
        this.selectedLocation = null;
        TimeAgo.addLocale(en);
        this.state = {
            data: {},
            gateways: {},
            pageSize: 50
        };
    }

    componentDidMount() {
        if (localStorage.getItem("selected_customer") == null) {
            history.push("/login");
        } else {
            this.props.dispatch(gatewayAction.getAllGateways());
            setTimeout(this.loadReceivers(), 2000);
            setTimeout(this.loadDispensers(), 2000);
        }
    }

    onGatewayChange(values) {
        if (values.length > 0) {
            this.selectedGateway = values[0].name;
        }
    }

    onLocationChange(values) {
        if (values.length > 0) {
            this.selectedLocation = values[0].name;
        }
    }

    loadDispensers() {
        this.props.dispatch(dispenserAction.getAllDispenser());
    }

    loadReceivers() {
        this.props.dispatch(receiversAction.getAllReceivers());
    }

    componentWillReceiveProps(nextProps) {
        const gateways = nextProps.gateways.items;
        const dispensers = nextProps.dispensers.items;
        const receivers = nextProps.receivers.items;

        let newGateways = {};
        let locationName = [];

        if (gateways && gateways.length > 0) {
            newGateways = gateways.map((currElement, index) => {
                return {id: index + 1, name: currElement.serialnum};
            });
        }

        if (receivers && receivers.length > 0) {
            for (let each of receivers) {
                locationName.push(each.locationName);
            }
        }

        let uniqueLocations = [...new Set(locationName)];
        let locationNamesArray = uniqueLocations.map((value, index) => {
            return {id: index + 1, name: value};
        })

        if (dispensers && dispensers.length > 0) {
            const timeAgo = new TimeAgo("en-US");
            let listItems = dispensers.map((dispenser, i) => ({
                serialnum: dispenser.serialnum,
                macaddrble: dispenser.macaddrble && dispenser.macaddrble.toLowerCase(),
                gateway: dispenser.gateway,
                locationName: dispenser.locationName,
                battery: dispenser.SensorBattery,
                timestamp: dispenser.TimeStamp,
                status: dispenser.TimeStamp != null ? (new Date().valueOf() - new Date(dispenser.TimeStamp * 1000)) / 1000 <= 1800 ?
                    "Online" : "Offline" : "Offline",
            }));

            let data = {
                columns: [
                    {
                        title: "Serial Number",
                        field: "serialnum",
                        editable: "never",
                        cellStyle: {
                            fontSize: 13
                        }
                    },
                    {
                        title: "Mac Address",
                        field: "macaddrble",
                        editable: "never",
                        cellStyle: {
                            fontSize: 13
                        }
                    },
                    {
                        title: "Gateway",
                        field: "gateway",
                        editComponent: props => (
                            <Select
                                placeholder={props.rowData.gateway}
                                options={newGateways}
                                labelField={"name"}
                                searchable={"name"}
                                searchBy={"name"}
                                onChange={(values) => this.onGatewayChange(values)}
                            />
                        ),
                        cellStyle: {
                            fontSize: 13
                        }
                    },
                    {
                        title: "Battery",
                        field: "battery",
                        editable: "never",
                        render: rowData => (
                            <div
                                style={{
                                    width: 40,
                                    height: 40,
                                    color:
                                        rowData.battery < 1000
                                            ? "black"
                                            : rowData.battery >= 1000 && rowData.battery < 2500
                                                ? "red"
                                                : rowData.battery >= 2500 && rowData.battery < 2760
                                                    ? "orange"
                                                    : rowData.battery >= 2760 && rowData.battery < 2830
                                                        ? "dodgerblue"
                                                        : "green"
                                }}
                            >
                                <Icon
                                    size={"100%"}
                                    icon={
                                        rowData.battery <= 1000
                                            ? batteryEmpty
                                            : rowData.battery >= 1000 && rowData.battery < 2500
                                                ? batteryEmpty
                                                : rowData.battery > 2500 && rowData.battery < 2760
                                                    ? batteryLow
                                                    : rowData.battery >= 2760 && rowData.battery < 2830
                                                        ? batteryHalf
                                                        : batteryFull
                                    }
                                />
                            </div>
                        ),
                        cellStyle: {
                            fontSize: 13
                        }
                    },
                    {
                        title: "Location",
                        field: "locationName",
                        editComponent: props => (
                            <Select
                                placeholder={props.rowData.location}
                                options={locationNamesArray}
                                labelField={"name"}
                                searchable={"name"}
                                searchBy={"name"}
                                onChange={(values) => this.onLocationChange(values)}
                            />
                        ),
                        cellStyle: {
                            fontSize: 13
                        }
                    },
                    {
                        title: "Last Updated",
                        field: "timestamp",
                        editable: "never",
                        render: rowData =>
                            rowData.timestamp != null ? (
                                <div>
                  <span>
                    {timeAgo.format(new Date(rowData.timestamp * 1000), "time")}
                  </span>
                                </div>
                            ) : (
                                "N/A"
                            ),
                        cellStyle: {
                            fontSize: 13
                        }
                    },
                    {
                        title: "Status",
                        field: "status",
                        editable: "never",
                        render: rowData => rowData.timestamp != null ? (new Date().valueOf() - new Date(rowData.timestamp * 1000)) / 1000 <= 1800 ?
                                <span className="online">Online</span> : <span className="offline">Offline</span> :
                            <span className="offline">Offline</span>,
                        cellStyle: {
                            fontSize: 13
                        }
                    }
                ],
                rows: listItems
            };
            this.setState(function (prevState, props) {
                return {
                    gateways: newGateways,
                    data: data
                };
            });
        }
    }

    render() {
        let dispensers = this.state.data;
        let pageSize = this.state.pageSize;
        return (
            <div>
                <NavigationBar/>
                <div className="table-style">
                    {Object.keys(dispensers).length === 0 &&
                        dispensers.constructor === Object && (
                            <MDSpinner className="loader-style"/>
                        )}
                    {Object.keys(dispensers).length > 0 &&
                        dispensers.constructor === Object && (
                            <MaterialTable
                                localization={{
                                    toolbar: {
                                        searchPlaceholder: "Search",
                                        exportName: "Export to Excel"
                                    },
                                    body: {
                                        deleteTooltip: "Delete"
                                    },
                                    pagination: {
                                        labelRowsSelect: "Rows"
                                    }
                                }}
                                columns={dispensers.columns}
                                data={dispensers.rows}
                                title="DISPENSERS"
                                options={{
                                    headerStyle: {
                                        backgroundColor: "#32669a",
                                        color: "#FFF",
                                        fontSize: 15
                                    },
                                    showTitle: true,
                                    actionsColumnIndex: -1,
                                    pageSize: pageSize,
                                    pageSizeOptions: [10, 25, 50, 100],
                                    searchFieldStyle: {
                                        backgroundColor: "#ff",
                                        color: "#32669a",
                                        fontSize: 18,
                                        borderColor: "#32669a"
                                    },
                                    paginationType: "stepped",
                                    showEmptyDataSourceMessage: true,
                                    exportButton: true,
                                    exportAllData: true,
                                    exportCsv: (columns, data) => {
                                        saveAsExcel(columns, data);
                                    }
                                }}
                                editable={{
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            let updateDispensers = {
                                                serialnum: newData.serialnum,
                                                gateway:
                                                    this.selectedGateway != null
                                                        ? this.selectedGateway
                                                        : newData.gateway,
                                                locationName: this.selectedLocation != null
                                                    ? this.selectedLocation
                                                    : newData.locationName,
                                            };
                                            this.props.dispatch(
                                                dispenserAction.saveDispenser(updateDispensers)
                                            );
                                            newData.gateway =
                                                this.selectedGateway != null
                                                    ? this.selectedGateway
                                                    : newData.gateway;

                                            newData.locationName = this.selectedLocation != null
                                                ? this.selectedLocation
                                                : newData.locationName;
                                            this.selectedLocation = null;

                                            let index = dispensers.rows.indexOf(oldData);
                                            dispensers.rows[index] = newData;
                                            this.setState = {
                                                data: dispensers
                                            };
                                            resolve();
                                        })
                                }}
                                onChangeRowsPerPage={event => {
                                    this.setState({pageSize: event});
                                }}
                            />
                        )}
                </div>
            </div>
        );
    }
}

async function saveAsExcel(columns, data) {
    const wb = new ExcelJS.Workbook();

    const ws = wb.addWorksheet();
    ws.columns = [
        {header: "Serial Number", key: "serialnum", width: 20},
        {header: "Mac Address", key: "macaddrble", width: 20},
        {header: "Location", key: "gojoLocationName", width: 20},
        {header: "Unit Name", key: "unitName", width: 20},
        {header: "Position", key: "position", width: 20},
        {header: "Battery", key: "battery", width: 20},
        {header: "Last Updated", key: "timestamp", width: 20}
    ];

    data.forEach(element => {
        let batteryInt =
            element.battery !== undefined ? parseInt(element.battery) / 1000 : 0;
        if (batteryInt > 0) {
            const rounded = Math.round(batteryInt * 10) / 10;
            batteryInt = rounded.toString() + "V";
        } else {
            batteryInt = "";
        }
        let dateString = "";
        if (element.timestamp !== undefined) {
            const date = new Date(element.timestamp * 1000);
            let hour = ((date.getHours() + 24) % 12 || 12).toString();
            let minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
            let dateStrin1 = hour
                .concat(":")
                .concat(minutes)
                .concat(date.getHours() >= 12 ? "PM" : "AM")
                .concat(" ");
            dateString =
                dateStrin1 +
                date.getMonth().toString() +
                "/" +
                date.getDate().toString() +
                "/" +
                date.getFullYear().toString();
        }

        const row = ws.addRow([
            element.serialnum,
            element.macaddrble,
            element.gojoLocationName,
            element.unitName,
            element.position,
            batteryInt,
            dateString
        ]);
        row.font = {bold: true};
    });

    const buf = await wb.xlsx.writeBuffer();
    saveAs(new Blob([buf]), "Dispensers.xlsx");
}

function mapStateToProps(state) {
    const {dispensers, gateways, receivers} = state;
    return {
        gateways,
        dispensers,
        receivers
    };
}

const connectedDispensersPage = connect(mapStateToProps)(DispensersPage);
export { connectedDispensersPage as DispensersPage };
