import {homeConstants} from "../_constants";

export function home(state = {}, action) {
    switch (action.type) {
        case homeConstants.GET_DASHBOARD_REQUEST:
            return {
                loading: true
            };
        case homeConstants.GET_DASHBOARD_REQUEST_SUCCESS:
            return {
                loading: false,
                dashboardData: action.dashboardData
            };
        case homeConstants.GET_DASHBOARD_REQUEST_FAILURE:
            return {
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}
