import {siteSettingsConstants} from "../_constants";
import {sitesettingsService} from "../_services";
import {alertActions} from "./";
import {history} from "../_helpers";

export const siteSettingsAction = {
    getSiteSettings,
    createSiteSettings,
    // updateSiteSettings,
    // deleteSiteSettings
};

function getSiteSettings() {
    return dispatch => {
        dispatch(request({}));
        sitesettingsService.getSiteSettings().then(
            siteSettings => {
                dispatch(success(siteSettings));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return {type: siteSettingsConstants.GET_ALL_SETTINGS_REQUEST};
    }

    function success(siteSettings) {
        return {
            type: siteSettingsConstants.GET_ALL_SETTINGS_REQUEST_SUCCESS,
            siteSettings
        };
    }

    function failure(error) {
        return {
            type: siteSettingsConstants.GET_ALL_SETTINGS_REQUEST_FAILURE,
            error
        };
    }
}

function createSiteSettings(data) {
    return dispatch => {
        dispatch(request({}));
        sitesettingsService.createSiteSettings(data).then(
            message => {
                dispatch(success(message));
                dispatch(alertActions.error(message.message));
                history.push("/sitesettings");
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return {type: siteSettingsConstants.CREATE_SETTINGS_REQUEST};
    }

    function success(message) {
        return {
            type: siteSettingsConstants.CREATE_SETTINGS_REQUEST_SUCCESS,
            message
        };
    }

    function failure(error) {
        return {
            type: siteSettingsConstants.CREATE_SETTINGS_REQUEST_FAILURE,
            error
        };
    }
}