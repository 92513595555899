export const userConstants = {
    REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
    REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
    REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

    LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
    LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
    LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

    RESET_PASSWORD_REQUEST: "USERS_RESET_PASSWORD_REQUEST",
    RESET_PASSWORD_SUCCESS: "USERS_RESET_PASSWORD_SUCCESS",
    RESET_PASSWORD_FAILURE: "USERS_RESET_PASSWORD_FAILURE",

    CONFIRM_PASSWORD_REQUEST: "USERS_CONFIRM_PASSWORD_REQUEST",
    CONFIRM_PASSWORD_SUCCESS: "USERS_CONFIRM_PASSWORD_SUCCESS",
    CONFIRM_PASSWORD_FAILURE: "USERS_CONFIRM_PASSWORD_FAILURE",

    LOGOUT: "USERS_LOGOUT"
};
