import config from "config";
import {authHeader} from "../_helpers";

export const sitesettingsService = {
    getSiteSettings,
    createSiteSettings,
    updateSiteSettings,
    deleteSiteSettings
};

function getSiteSettings() {
    let customer = JSON.parse(localStorage.getItem("selected_customer"));

    if (customer === null) {
        return "Customer not found";
    }

    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(
        `${config.apiUrl}/api/v1/sitesettings/` + customer.custId,
        requestOptions
    )
        .then(handleResponse)
        .then(sitesettings => {
            return sitesettings.data;
        });
}

function createSiteSettings(data) {
    let customer = JSON.parse(localStorage.getItem("selected_customer"));

    if (customer === null) {
        return "Customer not found";
    }

    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(
        `${config.apiUrl}/api/v1/sitesettings/` + customer.custId,
        requestOptions
    )
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function updateSiteSettings(data) {
    let customer = JSON.parse(localStorage.getItem("selected_customer"));

    if (customer === null) {
        return "Customer not found";
    }

    const requestOptions = {
        method: "PATCH",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };

    return fetch(
        `${config.apiUrl}/api/v1/sitesettings/` + customer.custId,
        requestOptions
    )
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function deleteSiteSettings(data) {
    let customer = JSON.parse(localStorage.getItem("selected_customer"));

    if (customer === null) {
        return "Customer not found";
    }

    const requestOptions = {
        method: "DELETE",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(data)
    };

    return fetch(
        `${config.apiUrl}/api/v1/sitesettings/` + customer.custId + "/" + data,
        requestOptions
    )
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload()
            }
            const error = (data && data.error) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}