import {liveFeedConstants} from "../_constants";

export function liveFeed(state = {}, action) {
    switch (action.type) {
        case liveFeedConstants.GET_LIVE_FEED_REQUEST:
            return {
                loading: true
            };
        case liveFeedConstants.GET_LIVE_FEED_REQUEST_SUCCESS:
            return {
                loading: false,
                items: action.feeds
            };
        case liveFeedConstants.GET_LIVE_FEED_REQUEST_FAILURE:
            return {
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}
