import config from "config";
import {authHeader} from "../_helpers";

export const dispenserService = {
    getAllDispenser,
    saveDispenser
};

function getAllDispenser() {
    let customer = JSON.parse(localStorage.getItem("selected_customer"));

    if (customer === null) {
        return "Customer not found";
    }

    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(
        `${config.apiUrl}/api/v1/dispcustId/` + customer.custId,
        requestOptions
    )
        .then(handleResponse)
        .then(dispensers => {
            return dispensers;
        });
}

function saveDispenser(dispenser) {
    const requestOptions = {
        method: "PATCH",
        headers: authHeader(),
        body: JSON.stringify(dispenser)
    };

    let serialNum = dispenser.serialnum;
    return fetch(`${config.apiUrl}/api/v1/disp/` + serialNum, requestOptions)
        .then(handleResponse)
        .then(dispensers => {
            return dispensers;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload();
            }
            const error = (data && data.error) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
