import config from "config";
import {authHeader} from "../_helpers";

export const tagsService = {
    getAllStaffTags,
    getAllAssetTags,
    getAllPWTags,
    saveTag
};

function getAllStaffTags() {
    let customer = JSON.parse(localStorage.getItem("selected_customer"));

    if (customer === null) {
        return "Customer not found";
    }

    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(
        `${config.apiUrl}/api/v1/stagcustid/` + customer.custId,
        requestOptions
    )
        .then(handleResponse)
        .then(tags => {
            return tags;
        });
}

function getAllAssetTags() {
    let customer = JSON.parse(localStorage.getItem("selected_customer"));

    if (customer === null) {
        return "Customer not found";
    }

    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(
        `${config.apiUrl}/api/v1/atagcustid/` + customer.custId,
        requestOptions
    )
        .then(handleResponse)
        .then(tags => {
            return tags;
        });
}

function getAllPWTags() {
    let customer = JSON.parse(localStorage.getItem("selected_customer"));

    if (customer === null) {
        return "Customer not found";
    }

    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(
        `${config.apiUrl}/api/v1/pwtagcustid/` + customer.custId,
        requestOptions
    )
        .then(handleResponse)
        .then(tags => {
            return tags;
        });
}

function saveTag(tag) {
    let serialNumber = tag.serialnum;
    const requestOptions = {
        method: "PATCH",
        headers: authHeader(),
        body: JSON.stringify(tag)
    };

    return fetch(`${config.apiUrl}/api/v1/tag/` + serialNumber, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload()
            }
            const error = (data && data.error) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
