import {gatewayConstants} from "../_constants";

export function gateways(state = {}, action) {
    switch (action.type) {
        case gatewayConstants.GET_ALL_GATEWAYS_REQUEST:
            return {
                loading: true
            };
        case gatewayConstants.GET_ALL_GATEWAYS_REQUEST_SUCCESS:
            return {
                loading: false,
                items: action.gateways
            };
        case gatewayConstants.GET_ALL_GATEWAYS_REQUEST_FAILURE:
            return {
                loading: false,
                error: action.error
            };
        case gatewayConstants.UPDATE_GATEWAYS_REQUEST:
            return {
                isSavingData: false
            };
        case gatewayConstants.UPDATE_GATEWAYS_REQUEST_SUCCESS:
            return {
                isSavingData: true,
                response: action.response
            };
        case gatewayConstants.UPDATE_GATEWAYS_REQUEST_FAILURE:
            return {
                isSavingData: false,
                error: action.error
            };
        default:
            return state;
    }
}
