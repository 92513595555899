import {receiversConstants} from "../_constants";
import {receiversService} from "../_services";
import {alertActions} from "./";

export const receiversAction = {
    getAllReceivers,
    saveReceiver
};

function getAllReceivers() {
    return dispatch => {
        dispatch(request({}));
        receiversService.getAllReceivers().then(
            receivers => {
                dispatch(success(receivers));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return {type: receiversConstants.GET_ALL_RECEIVERS_REQUEST};
    }

    function success(receivers) {
        return {
            type: receiversConstants.GET_ALL_RECEIVERS_REQUEST_SUCCESS,
            receivers
        };
    }

    function failure(error) {
        return {
            type: receiversConstants.GET_ALL_RECEIVERS_REQUEST_FAILURE,
            error
        };
    }
}

function saveReceiver(receiver) {
    return dispatch => {
        dispatch(request({}));
        receiversService.saveReceiver(receiver).then(
            response => {
                dispatch(success(response));
                if (response.message === "PATCHED") {
                    dispatch(success(response.message));
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return {type: receiversConstants.UPDATE_RECEIVERS_REQUEST};
    }

    function success(response) {
        return {
            type: receiversConstants.UPDATE_RECEIVERS_REQUEST_SUCCESS,
            response
        };
    }

    function failure(error) {
        return {
            type: receiversConstants.UPDATE_RECEIVERS_REQUEST_FAILURE,
            error
        };
    }
}
