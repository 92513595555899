import {dispenserConstants} from "../_constants";
import {dispenserService} from "../_services";
import {alertActions} from "./";

export const dispenserAction = {
    getAllDispenser,
    saveDispenser
};

function getAllDispenser() {
    return dispatch => {
        dispatch(request({}));
        dispenserService.getAllDispenser().then(
            dispensers => {
                dispatch(success(dispensers));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return {type: dispenserConstants.GET_ALL_DISPENSER_REQUEST};
    }

    function success(dispensers) {
        return {
            type: dispenserConstants.GET_ALL_DISPENSER_REQUEST_SUCCESS,
            dispensers
        };
    }

    function failure(error) {
        return {
            type: dispenserConstants.GET_ALL_DISPENSER_REQUEST_FAILURE,
            error
        };
    }
}

function saveDispenser(dispenser) {
    return dispatch => {
        dispatch(request({}));
        dispenserService.saveDispenser(dispenser).then(
            response => {
                dispatch(success(response));
                dispatch(alertActions.success(response.message));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return {type: dispenserConstants.UPDATE_DISPENSER_REQUEST};
    }

    function success(response) {
        return {
            type: dispenserConstants.UPDATE_DISPENSER_REQUEST_SUCCESS,
            response
        };
    }

    function failure(error) {
        return {
            type: dispenserConstants.UPDATE_DISPENSER_REQUEST_FAILURE,
            error
        };
    }
}
