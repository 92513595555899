import config from "config";

export const userService = {
    login,
    logout,
    register,
    resetPassword,
    confirmPassword
};

function login(username, password) {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({username: username, password: password})
    };

    return fetch(`${config.apiUrl}/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return user;
        }).then(user => {
            const requestOptions = {
                method: "GET",
                headers: {
                    "x-access-token": user.token,
                    "x-key": user.email,
                    "Content-Type": "application/json"
                }
            };
            let url = `${config.apiUrl}/api/v1/customers?filter={"custId":${JSON.stringify(user.authCustId)}}`
            return fetch(url, requestOptions)
                .then(handleResponse)
                .then(customers => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    let sortedCustomers = customers.sort((a, b) => (a.facility > b.facility) ? 1 : -1)
                    console.log(sortedCustomers);

                    let defaultCustomer = sortedCustomers[0];
                    localStorage.setItem(
                        "selected_customer",
                        JSON.stringify(defaultCustomer)
                    );
                    user.authCustId = sortedCustomers;
                    localStorage.setItem("user", JSON.stringify(user));
                    return user;
                });
        });
}

function logout() {
    // remove user from local storage to log user out
    // Remove Data
    localStorage.removeItem("selected_customer");
    localStorage.removeItem("user");
    return true;
}

function register(user) {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(user)
    };

    return fetch(`${config.apiUrl}/users/register`, requestOptions).then(
        handleResponse
    );
}

function resetPassword(email) {
    const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({email: email})
    };

    return fetch(`${config.apiUrl}/resetpassword`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function confirmPassword(user) {
    let postData = {
        email: user.email,
        newpassword: user.newpassword,
        confirmpassword: user.confirmpassword,
    }
    if (user.token != null) {
        postData["token"] = user.token;
    }
    if (user.defaultPassword != null) {
        postData["defaultpassword"] = user.defaultPassword;
    }
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(postData)
    };

    return fetch(`${config.apiUrl}/confirmpassword`, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
            }
            const error = (data && data.error) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
