import config from "config";
import {authHeader} from "../_helpers";

export const escalationsService = {
    getAllEscalations,
    getAllLocations,
    saveEscalation,
    updateEscalation,
    deleteEscalation
};

function getAllEscalations() {
    let customer = JSON.parse(localStorage.getItem("selected_customer"));

    if (customer === null) {
        return ("Customer not found");
    }

    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(
        `${config.apiUrl}/api/v1/escalations/` + customer.custId,
        requestOptions
    )
        .then(handleResponse)
        .then(unitNames => {
            return unitNames;
        });
}

function getAllLocations() {
    let customer = JSON.parse(localStorage.getItem("selected_customer"));

    if (customer === null) {
        return ("Customer not found");
    }

    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(
        `${config.apiUrl}/api/v1/rxs/locations/` + customer.custId,
        requestOptions
    )
        .then(handleResponse)
        .then(locations => {
            return locations;
        });
}

function saveEscalation(data) {
    let customer = JSON.parse(localStorage.getItem("selected_customer"));

    if (customer === null) {
        return ("Customer not found");
    }

    const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(
        `${config.apiUrl}/api/v1/escalations/` + customer.custId,
        requestOptions
    )
        .then(handleResponse)
        .then(templates => {
            return templates;
        });
}

function updateEscalation(name, data) {
    let customer = JSON.parse(localStorage.getItem("selected_customer"));

    if (customer === null) {
        return ("Customer not found");
    }

    const requestOptions = {
        method: "PATCH",
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(
        `${config.apiUrl}/api/v1/escalations/` + name,
        requestOptions
    )
        .then(handleResponse)
        .then(templates => {
            return templates;
        });
}

function deleteEscalation(name) {
    let customer = JSON.parse(localStorage.getItem("selected_customer"));

    if (customer === null) {
        return ("Customer not found");
    }

    const requestOptions = {
        method: "DELETE",
        headers: authHeader(),
    };

    return fetch(
        `${config.apiUrl}/api/v1/escalations/` + name,
        requestOptions
    )
        .then(handleResponse)
        .then(templates => {
            return templates;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }
            const error = (data && data.error) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
