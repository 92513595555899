import {gatewayConstants} from "../_constants";
import {gatewayService} from "../_services";
import {alertActions} from "./alert.actions";

export const gatewayAction = {
    getAllGateways,
    saveGateway
};

function getAllGateways() {
    return dispatch => {
        dispatch(request({}));
        gatewayService.getAllGateways().then(
            gateways => {
                dispatch(success(gateways));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return {type: gatewayConstants.GET_ALL_GATEWAYS_REQUEST};
    }

    function success(gateways) {
        return {
            type: gatewayConstants.GET_ALL_GATEWAYS_REQUEST_SUCCESS,
            gateways
        };
    }

    function failure(error) {
        return {
            type: gatewayConstants.GET_ALL_GATEWAYS_REQUEST_FAILURE,
            error
        };
    }
}

function saveGateway(gateway) {
    return dispatch => {
        dispatch(request({}));
        gatewayService.saveGateway(gateway).then(
            response => {
                dispatch(success(response));
                dispatch(alertActions.success(response.message));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() {
        return {type: gatewayConstants.UPDATE_GATEWAYS_REQUEST};
    }

    function success(response) {
        return {
            type: gatewayConstants.UPDATE_GATEWAYS_REQUEST_SUCCESS,
            response
        };
    }

    function failure(error) {
        return {
            type: gatewayConstants.UPDATE_GATEWAYS_REQUEST_FAILURE,
            error
        };
    }
}
