import {siteSettingsConstants} from "../_constants";

export function siteSettings(state = {}, action) {
    switch (action.type) {
        case siteSettingsConstants.GET_ALL_SETTINGS_REQUEST:
            return {
                loading: true
            };
        case siteSettingsConstants.GET_ALL_SETTINGS_REQUEST_SUCCESS:
            return {
                loading: false,
                items: action.siteSettings
            };
        case siteSettingsConstants.GET_ALL_SETTINGS_REQUEST_FAILURE:
            return {
                loading: false,
                error: action.error
            };
        case siteSettingsConstants.CREATE_SETTINGS_REQUEST:
            return {
                loading: true
            };
        case siteSettingsConstants.CREATE_SETTINGS_REQUEST_SUCCESS:
            return {
                loading: false,
                items: action.message
            };
        case siteSettingsConstants.CREATE_SETTINGS_REQUEST_FAILURE:
            return {
                loading: false,
                error: action.error
            };
        default:
            return null;
    }
}