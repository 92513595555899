import React from "react";
import {connect} from "react-redux";
import {NavigationBar} from "../Components/NavigationBar";
import {history} from "../_helpers";
import {Checkbox, ListItemText, MenuItem, OutlinedInput, Paper, Select, TextField} from '@material-ui/core';

import "./EscalationChainPage.css";
import MDSpinner from "react-md-spinner";
import MaterialTable from "material-table";
import {escalationsAction} from "../_actions";
import Button from "@material-ui/core/Button";
import {withStyles} from '@material-ui/core/styles';
import {Multiselect} from "multiselect-react-dropdown";
import {element, number} from "prop-types";

const globPageName = {
    escalationList: "escalationList",
    addNewEscalation: "addNewEscalation",
}

const StyledButton = withStyles({
    root: {
        background: "green",
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 40,
        padding: '0 30px',
        fontSize: 14,
        position: 'right: 0;',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 400,
        },
    },
};


class EscalationChainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            escalations: null,
            locations: null,
            pageName: globPageName.escalationList,
            name: null,
            description: null,
            minutes: 0,
            type: 1,
            selectedLocations: [],
            selectedValues: []
        };
    }

    componentDidMount() {
        if (localStorage.getItem("selected_customer") == null) {
            history.push("/login");
        } else {
            console.log("Can proceed with loading data")
            this.props.dispatch(escalationsAction.getAllLocations());
        }
    }

    static getDerivedStateFromProps(props, state) {
        console.log("getDerivedStateFromProps")
        const update = {};
        update.loading = props.loading;

        if (state.escalations !== null && state.escalations !== undefined) {
            update.escalations = state.escalations;
        }
        if (props.escalations !== null && props.escalations !== undefined) {
            update.escalations = props.escalations;
        }
        if (props.locations !== null && props.locations !== undefined) {
            update.locations = props.locations;
            props.dispatch(escalationsAction.getAllEscalations());
        }
        if (props.response != null) {
            update.pageName = globPageName.escalationList;
            props.dispatch(escalationsAction.getAllEscalations());
        }
        return update;
    }

    _onSubmit(e) {
        e.preventDefault()

        // Validate the data again
        let name = this.state.name;
        let description = this.state.description;
        let minutes = this.state.minutes;
        let locations = this.state.selectedLocations;
        let type = "";
        switch (this.state.type) {
            case 1:
                type = "Rounding";
                locations = [];
                break;
            case 2:
                type = "Par Level";
                minutes = 0
                locations = [];
                break;
            case 3:
                type = "Egress";
                break;
            case 4:
                type = "Custom";
                locations = [];
                break;
            default:
                type = "Unknown";
                locations = [];
        }
        let data = {
            escalationName: name,
            description: description,
            minutes: minutes,
            type: type,
            sensors: locations
        }
        this.props.dispatch(escalationsAction.saveEscalation(data));
        console.log("Submit button")
    }

    handleChangeInTextField = (event) => {
        if (event.target.id === 'name_filed') {
            this.setState({
                name: event.target.value
            })
        } else if (event.target.id === 'desc_filed') {
            this.setState({
                description: event.target.value
            })
        } else if (event.target.id === 'mins_filed') {
            const mins = parseInt(event.target.value) || 0;
            this.setState({
                minutes: mins
            })
        }
    }

    handleChange = (event) => {
        this.setState({
            type: event.target.value
        })
    }

    handleChangeLocation = (event) => {
        const {
            target: {value},
        } = event;
        this.setState({
            selectedLocations: value
        })
    };

    onSelect(selectedList, selectedItem) {
        this.state.selectedValues = selectedList;
    }

    onRemove(selectedList, removedItem) {
        this.state.selectedValues = selectedList;
    }

    render() {
        let names = this.state.locations;
        if (names == null) {
            names = [];
        }
        const selectedLocations = this.state.selectedLocations;
        const loading = this.state.loading;
        let pageName = this.state.pageName;
        let escalationList = this.state.escalations;
        let type = this.state.type;

        let dspEscalationList = null;
        if (escalationList != null) {
            let locations = this.state.locations;
            dspEscalationList = this.prepareEscalationListData(escalationList, locations)
        }

        return (
            <div>
                <NavigationBar/>
                {loading === true && (
                    <MDSpinner className="loader-style"/>
                )}
                {pageName === 'escalationList' && dspEscalationList != null && (
                    <div className="table-style">
                        <StyledButton onClick={() => {
                            this.setState({
                                pageName: globPageName.addNewEscalation
                            })
                        }}>Create Escalation</StyledButton>
                        <MaterialTable columns={dspEscalationList.columns}
                                       data={dspEscalationList.rows}
                                       title="Escalations"
                                       options={{
                                           headerStyle: {
                                               backgroundColor: "#32669a",
                                               color: "#FFF",
                                               fontSize: 15
                                           },
                                           showTitle: true,
                                           actionsColumnIndex: -1,
                                           paging: false,
                                           showEmptyDataSourceMessage: true,
                                           exportButton: false,
                                           exportAllData: false,
                                       }}
                                       editable={{
                                           onRowUpdate: (newData, oldData) =>
                                               new Promise((resolve, reject) => {
                                                   let escalationName = newData.escalationName;
                                                   let updateEscalation = {
                                                       description: newData.description,
                                                       minutes: newData.type === 'Egress' ? 0 : newData.minutes,
                                                       sensors: newData.type === 'Egress' ? this.state.selectedValues.map((element) => element.name) : []
                                                   };
                                                   this.props.dispatch(escalationsAction.updateEscalation(escalationName, updateEscalation));
                                                   resolve();

                                               })
                                       }}
                                       actions={[
                                           rowData => ({
                                               icon: 'delete',
                                               tooltip: 'Delete User',
                                               onClick: (event, rowData) => {
                                                   console.log("Delete")
                                                   let escalationName = rowData.escalationName;
                                                   if (confirm('Are you sure you want to delete this?')) {
                                                       // Save it!
                                                       this.props.dispatch(escalationsAction.deleteEscalation(escalationName));
                                                       console.log('Thing was saved to the database.');
                                                   } else {
                                                       // Do nothing!
                                                       console.log('Thing was not saved to the database.');
                                                   }
                                               }
                                           })
                                       ]}
                                       onRowClick={((evt, selectedRow) => {
                                           // this.setState({selectedUnit: selectedRow.unitName});
                                       })}
                        />
                    </div>
                )}
                {pageName === 'addNewEscalation' && (
                    <center>
                        <Paper className={"pager_style"} variant={"elevation"}>
                            <p className={'paper_title'}>Create new escalation</p>
                            <form onSubmit={this._onSubmit.bind(this)}>
                                <br/>
                                <br/>
                                <TextField
                                    id={"name_filed"}
                                    className={"text_field_style"}
                                    variant={"outlined"}
                                    onChange={this.handleChangeInTextField}
                                    placeholder="Name"
                                    type={"string"}
                                    required={true}
                                    autoComplete='off'
                                />
                                <br/>
                                <br/>
                                <TextField
                                    id={"desc_filed"}
                                    className={"text_field_style"}
                                    variant={"outlined"}
                                    onChange={this.handleChangeInTextField}
                                    placeholder="Description"
                                    type={"string"}
                                    required={true}
                                    autoComplete='off'
                                />
                                <br/>
                                <br/>
                                <Select
                                    labelId="escalation_type_section"
                                    className={"select_box_style"}
                                    id="escalation_type_section"
                                    variant={"outlined"}
                                    value={type}
                                    onChange={this.handleChange}
                                >
                                    <MenuItem value={1}>Rounding</MenuItem>
                                    <MenuItem value={2}>Par Level</MenuItem>
                                    <MenuItem value={3}>Egress</MenuItem>
                                    <MenuItem value={4}>Custom</MenuItem>
                                </Select>
                                <br/>
                                <br/>
                                {type === 1 && (
                                    <TextField
                                        id={"mins_filed"}
                                        className={"text_field_style"}
                                        variant={"outlined"}
                                        onChange={this.handleChangeInTextField}
                                        placeholder="Minutes"
                                        type={"number"}
                                        required={true}
                                        autoComplete='off'
                                    />
                                )}
                                {type === 3 && (
                                    <Select
                                        displayEmpty
                                        className={"select_field_style"}
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={selectedLocations}
                                        onChange={this.handleChangeLocation}
                                        input={<OutlinedInput/>}
                                        inputProps={{'aria-label': 'Without label'}}
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                                return <em>Select Location(s)</em>;
                                            }
                                            return selected.join(', ');
                                        }}
                                    >
                                        {names.map((name) => (
                                            <MenuItem key={name} value={name}>
                                                <Checkbox checked={selectedLocations.indexOf(name) > -1}/>
                                                <ListItemText primary={name}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                                <br/>
                                <br/>
                                <div>
                                    <Button className={"save_button_style"} type="submit" variant="contained"
                                            color="primary" size={"large"}>
                                        Save
                                    </Button>
                                </div>
                                <br/>
                                <br/>

                            </form>
                        </Paper>
                    </center>
                )}
            </div>
        );
    }

    prepareEscalationListData(escalationList, locations) {
        console.log("prepareEscalationListData");
        if (escalationList === undefined || escalationList == null || escalationList.length === 0) {
            return {columns: [], row: []};
        }
        let listItems = escalationList.data.map((escalation) => ({
            escalationName: escalation.escalationName,
            description: escalation.description,
            minutes: escalation.type === "Egress" ? (escalation.sensors !== null ? escalation.sensors.join(", ") : "N/A") : escalation.minutes,
            type: escalation.type,
            preSelected: escalation.sensors !== null ? escalation.sensors.map((element, index) => ({
                name: element, id: number + 1
            })) : []
        }));

        let locationOptions = []

        for (const [i, value] of locations.entries()) {
            locationOptions.push({name: value, id: i + 1});
        }
        return {
            columns: [{
                title: "Escalation Name",
                field: "escalationName",
                editable: "never",
                cellStyle: {
                    fontSize: 13
                }
            }, {
                title: "Description",
                field: "description",
                cellStyle: {
                    fontSize: 13
                }
            },
                {
                    title: "Minutes/Locations",
                    field: "minutes",
                    editable: (_, rowData) => rowData && rowData.type !== 'Par Level',
                    editComponent: props => (
                        props.rowData.type === 'Egress' ?
                            <Multiselect
                                options={locationOptions} // Options to display in the dropdown
                                placeholder={"Pick"}
                                selectedValues={props.rowData.preSelected} // Preselected value to persist in dropdown
                                onSelect={this.onSelect.bind(this)} // Function will trigger on select event
                                onRemove={this.onRemove.bind(this)} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                            /> :
                            <TextField
                                value={props.rowData.minutes}
                                onChange={e => props.onChange(e.target.value)}
                                type="numeric"
                            />
                    ),
                    cellStyle: {
                        fontSize: 13
                    }
                },
                {
                    title: "Type",
                    field: "type",
                    hidden: true,
                    cellStyle: {
                        fontSize: 13
                    }
                }
            ],
            rows: listItems
        };
    }
}


function mapStateToProps(state) {
    const {loading, templates, escalations, locations, response} = state.escalations;
    return {
        loading,
        escalations,
        locations,
        templates,
        response
    };
}

const connectedEscalationChainPage = connect(mapStateToProps)(EscalationChainPage);
export {connectedEscalationChainPage as EscalationChainPage};
