import React, {Component} from "react";
import {connect} from "react-redux";
import {userActions} from "../_actions";
import {Nav, Navbar} from "react-bootstrap";

import {MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBBtn} from "mdbreact";

import "./NavigationBar.css";

class NavigationBar extends Component {
    constructor(props) {
        super(props);

        let user = JSON.parse(localStorage.getItem("user"));
        let customers = user.authCustId;

        // Load users from storage
        this.state = {
            loading: false,
            users: customers
        };
        this.handleLogout = this.handleLogout.bind(this);
        this.handleCustomerSelection = this.handleCustomerSelection.bind(this);
    }

    handleLogout(event) {
        event.preventDefault();
        console.log("Logout action");
        this.props.dispatch(userActions.logout());
        window.location.reload();
    }

    handleCustomerSelection(event) {
        const users = this.state.users;

        event.preventDefault();
        console.log("Selected Customer");
        console.log(event.target.innerText);
        const customerObject = users.filter(
            customer => customer.facility === event.target.innerText
        );
        console.log(customerObject);
        localStorage.setItem(
            "selected_customer",
            JSON.stringify(customerObject[0])
        );
        window.location.reload();
    }

    render() {
        const users = this.state.users;

        let selectedUser = JSON.parse(localStorage.getItem("selected_customer"));
        const listItems =
            users &&
            users.map((user, i) => (
                <div key={i}>
                    <MDBDropdownItem className="divider-style" divider/>
                    <MDBDropdownItem
                        tag="div"
                        className="menu-style"
                        onClick={this.handleCustomerSelection}
                    >
                        {user.facility}
                    </MDBDropdownItem>
                    <MDBDropdownItem className="divider-style" divider/>
                </div>
            ));
        return (
            <div>
                <Navbar bg="primary" variant="light">
                    <Navbar.Brand href="/">Locate+</Navbar.Brand>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link href="/gateways">GATEWAYS</Nav.Link>
                            <Nav.Link href="/rxs">RECEIVERS</Nav.Link>
                            <Nav.Link href="/dispensers">DISPENSERS</Nav.Link>
                            <Nav.Link href="/stags">STAFF TAGS</Nav.Link>
                            <Nav.Link href="/atags">ASSET TAGS</Nav.Link>
                            <Nav.Link href="/pwtags">PW TAGS</Nav.Link>
                            <Nav.Link href="/escalations">ESCALATION CHAIN</Nav.Link>
                            {/* <Nav.Link href="/livefeed">LIVE FEED</Nav.Link> */}
                        </Nav>
                        <Nav className="right-navbar-style mr-auto">
                            <MDBDropdown>
                                <MDBDropdownToggle caret color="warning">
                                    Profile
                                </MDBDropdownToggle>
                                <MDBDropdownMenu
                                    right
                                    basic
                                    color="success"
                                    className="account-menu-style"
                                >
                                    <MDBDropdownItem href="/account">Account</MDBDropdownItem>
                                    <MDBDropdownItem className="divider-style" divider/>
                                    <MDBDropdownItem href="/logout" onClick={this.handleLogout}>
                                        Logout
                                    </MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </Nav>
                        <Nav className="right-navbar-style mr-auto">
                            <MDBDropdown>
                                {users && (
                                    <div>
                                        <MDBDropdownToggle color="warning">
                                            {selectedUser.facility}
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu
                                            right
                                            basic
                                            color="success"
                                            className="customer-menu-style"
                                            basic
                                        >
                                            <MDBDropdownItem header className="menu-header-style">
                                                Select Customer
                                            </MDBDropdownItem>
                                            {listItems}
                                        </MDBDropdownMenu>
                                    </div>
                                )}
                            </MDBDropdown>
                        </Nav>
                        {/*<Nav className="right-navbar-style mr-auto">*/}
                        {/*    <Fragment >*/}
                        {/*        <MDBBtn color="warning" href={"/sitesettings"}>Site Settings</MDBBtn>*/}
                        {/*    </Fragment>*/}
                        {/*</Nav>*/}
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {users} = state;
    return {
        users
    };
}

const connectedNavigationBar = connect(mapStateToProps)(NavigationBar);
export {connectedNavigationBar as NavigationBar};
