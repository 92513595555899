import {applyMiddleware, createStore} from "redux";
import thunkMiddleware from "redux-thunk";
import {createLogger} from "redux-logger";
import rootReducer from "../_reducers";

const loggerMiddleware = createLogger();

let middleWare = applyMiddleware(thunkMiddleware);
if (process.env.NODE_ENV === "development") {
    middleWare = applyMiddleware(thunkMiddleware, loggerMiddleware);
}
export const store = createStore(rootReducer, middleWare);
