import {escalationsConstants} from "../_constants";

export function escalations(state = {}, action) {
    switch (action.type) {
        case escalationsConstants.GET_ALL_ESCALATIONS_REQUEST:
        case escalationsConstants.ADD_NEW_ESCALATIONS_REQUEST:
        case escalationsConstants.UPDATE_ESCALATION_REQUEST:
        case escalationsConstants.DELETE_ESCALATION_REQUEST:
            return {
                loading: true
            };
        case escalationsConstants.UPDATE_ESCALATION_SUCCESS:
        case escalationsConstants.ADD_NEW_ESCALATIONS_SUCCESS:
        case escalationsConstants.DELETE_ESCALATION_SUCCESS:
            return {
                loading: false,
                response: action.response
            };
        case escalationsConstants.GET_ALL_ESCALATIONS_SUCCESS:
            return {
                loading: false,
                escalations: action.escalations
            };
        case escalationsConstants.GET_ALL_LOCATIONS_SUCCESS:
            return {
                locations: action.locations
            };
        case escalationsConstants.GET_ALL_ESCALATIONS_FAILURE:
        case escalationsConstants.ADD_NEW_ESCALATIONS_FAILURE:
        case escalationsConstants.UPDATE_ESCALATION_FAILURE:
        case escalationsConstants.DELETE_ESCALATION_FAILURE:
            return {
                loading: false,
                error: action.error
            };
        default:
            return state;
    }
}
