import config from "config";
import {authHeader} from "../_helpers";

export const receiversService = {
    getAllReceivers,
    saveReceiver
};

function getAllReceivers() {
    let customer = JSON.parse(localStorage.getItem("selected_customer"));

    if (customer === null) {
        return (error = "Customer not found");
    }

    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(
        `${config.apiUrl}/api/v1/rxcustId/` + customer.custId,
        requestOptions
    )
        .then(handleResponse)
        .then(receivers => {
            return receivers;
        });
}

function getOneReceiver(serialnum) {
    const requestOptions = {
        method: "GET",
        headers: authHeader()
    };

    return fetch(`${config.apiUrl}/api/v1/rx/` + serialnum, requestOptions)
        .then(handleResponse)
        .then(receiver => {
            return receiver;
        });
}

function saveReceiver(receiver) {
    let serialNumber = receiver.serialnum;
    const requestOptions = {
        method: "PATCH",
        headers: authHeader(),
        body: JSON.stringify(receiver)
    };

    return fetch(`${config.apiUrl}/api/v1/rx/` + serialNumber, requestOptions)
        .then(handleResponse)
        .then(response => {
            return response;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }
            const error = (data && data.error) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}
