import React, {Component} from "react";
import {connect} from "react-redux";
import {NavigationBar} from "../Components/NavigationBar";
import {homeAction} from "../_actions";
import {history} from "../_helpers";
import ChartistGraph from "react-chartist";
import "./HomePage.css";

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                gateways: {
                    total_devices: 0,
                    online_devices: 0,
                    offline_devices: 0
                },
                receivers: {
                    total_devices: 0,
                    online_devices: 0,
                    offline_devices: 0
                },
                dispensers: {
                    total_devices: 0,
                    online_devices: 0,
                    offline_devices: 0
                },
                tags: {
                    total_devices: 0,
                    online_devices: 0,
                    offline_devices: 0
                }
            }
        };
    }

    componentDidMount() {
        if (localStorage.getItem("selected_customer") == null) {
            history.push("/login");
        } else {
            this.props.dispatch(homeAction.getDashbaordData());
        }
    }

    componentWillReceiveProps(nextProps) {
      const dashboardData = nextProps.home.dashboardData;
      if (dashboardData) {
            this.setState(function (prevState, props) {
                return {data: dashboardData};
            });
        }
    }

    render() {
        let dashboardData = this.state.data;

        let chartType = "Pie";

        return (
            <div>
                <NavigationBar/>
                <div className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="header">
                                        <h4 className="title">Gateways</h4>
                                        <p className="category">
                                            Total: {dashboardData.gateways.total_devices}
                                        </p>
                                    </div>
                                    <div className="content">
                                        <ChartistGraph
                                            data={{
                                                labels: [
                                                    "Online (" +
                                                    dashboardData.gateways.online_devices.toString() +
                                                    ")",
                                                    "Offline (" +
                                                    dashboardData.gateways.offline_devices +
                                                    ")"
                                                ],
                                                series: [
                                                    dashboardData.gateways.online_devices,
                                                    dashboardData.gateways.offline_devices
                                                ],
                                                colors: ["#333", "#222", "#111", "#000"]
                                            }}
                                            options={{
                                                startAngle: 0,
                                                total: dashboardData.gateways.total_devices,
                                                showLabel: true,
                                                axisX: {
                                                    showGrid: false,
                                                    offset: 0
                                                },
                                                axisY: {
                                                    offset: 0
                                                },
                                                ignoreEmptyValues: true
                                            }}
                                            type={chartType}
                                            className={"ct-chart ct-perfect-fourth"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="header">
                                        <h4 className="title">Receivers</h4>
                                        <p className="category">
                                            Total: {dashboardData.receivers.total_devices}
                                        </p>
                                    </div>
                                    <div className="content">
                                        <ChartistGraph
                                            data={{
                                                labels: ["Online (" + dashboardData.receivers.online_devices.toString() + ")", "Offline (" + dashboardData.receivers.offline_devices.toString() + ")"],
                                                series: [
                                                    dashboardData.receivers.online_devices,
                                                    dashboardData.receivers.offline_devices
                                                ]
                                            }}
                                            options={{
                                                startAngle: 0,
                                                total: dashboardData.receivers.total_devices,
                                                showLabel: true,
                                                axisX: {
                                                    showGrid: false,
                                                    offset: 0
                                                },
                                                axisY: {
                                                    offset: 0
                                                },
                                                ignoreEmptyValues: true
                                            }}
                                            type={chartType}
                                            className={"ct-chart ct-perfect-fourth"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {home} = state;
    return {
        home
    };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export {connectedHomePage as HomePage};
