import React from "react";
import {connect} from "react-redux";
import {userActions} from "../_actions";
import {NavigationBar} from "../Components/NavigationBar";

import "./AccountPage.css";

class AccountPage extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        // if (this.probs.authentication === true) {
        //     this.props.dispatch(userActions.getAccount());
        // }
    }

    render() {
        const {user} = this.props;

        return (
            <div>
                <NavigationBar/>
                {<div>{user && <em>user</em>}</div>}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {user} = state;
    return {
        user
    };
}

const connectedAccountPage = connect(mapStateToProps)(AccountPage);
export {connectedAccountPage as AccountPage};
