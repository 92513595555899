import {dispenserConstants} from "../_constants";

export function dispensers(state = {}, action) {
    switch (action.type) {
        case dispenserConstants.GET_ALL_DISPENSER_REQUEST:
            return {
                loading: true
            };
        case dispenserConstants.GET_ALL_DISPENSER_REQUEST_SUCCESS:
            return {
                loading: false,
                items: action.dispensers
            };
        case dispenserConstants.GET_ALL_DISPENSER_REQUEST_FAILURE:
            return {
                loading: false,
                error: action.error
            };
        case dispenserConstants.UPDATE_DISPENSER_REQUEST:
            return {
                isSavingData: false
            };
        case dispenserConstants.UPDATE_DISPENSER_REQUEST_SUCCESS:
            return {
                isSavingData: true,
                response: action.response
            };
        case dispenserConstants.UPDATE_DISPENSER_REQUEST_FAILURE:
            return {
                isSavingData: false,
                error: action.error
            };
        default:
            return state;
    }
}
